import { GroupIds } from "../../models/enumerations/group-ids";
import { ReportMovementData } from "../../models/interfaces/reports/report-movement-data";
import ReportGenericHeader from "./report-generic-header";
import { useTranslation } from 'react-i18next';

const COMPONENT_CLASS = "c-report";

// -----------------------------------------------------------------------------------------
// #region Intefaces
// -----------------------------------------------------------------------------------------

interface ReportMovementProps {
    data: ReportMovementData;
}

// #endregion Intefaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const ReportMovement: React.FC<ReportMovementProps> = (props: ReportMovementProps) => {
    const { data } = props;
    const { t, i18n } = useTranslation();

    const formatter = new Intl.ListFormat(i18n.language, {
        style: 'long',
        type: 'unit',
    });

    data.users.sort(function compareFn(a, b): number {
        if (a?.name == null) {
            return -1;
        }
        if (b?.name == null) {
            return 1;
        }
        const nameA = a.name.toLocaleLowerCase();
        const nameB = b.name.toLocaleLowerCase();
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    });

    const formatNumber = (percent?: number): string => {
        if (percent == null) {
            return t('general.notApplicable');
        }
        return percent.toString();
    }

    const isLow = (groupId: string, percent?: number, pass?: boolean): boolean => {


        if (percent) {
            switch (groupId) {
                // From https://docs.google.com/spreadsheets/d/14DSKCtdkPA4eeBhkTjZy-4aDrfP_ozjPU48oyIR9IU0/edit#gid=449420902
                case GroupIds.TOE_TOUCH:
                    return percent < 75;
                case GroupIds.SHOULDER_MOBILITY:
                    return percent <= 65;
                case GroupIds.ROTATION:
                    return percent <= 69;
                case GroupIds.SQUAT:
                    return percent <= 49
                case GroupIds.BALANCE:
                    return percent <= 69
                case GroupIds.MOVEMENT_HEALTH:
                    return percent <= 65;
                default:
                    return false;
            }
        }
        else if (pass) {
            switch (groupId) {
                // From https://docs.google.com/spreadsheets/d/14DSKCtdkPA4eeBhkTjZy-4aDrfP_ozjPU48oyIR9IU0/edit#gid=449420902
                case GroupIds.SHOULDER_CLEARING:
                    return pass;
                case GroupIds.SPINE_CLEARING:
                    return pass;
                default:
                    return false;
            }
        }
        return false;
    }

    return (
        <div className={`${COMPONENT_CLASS} -movement`}>
            <ReportGenericHeader companyName={data.organizationName} reportName={t('reports.movement.reportName')} />
            <div className="flex gap-6 pt-6 pb-6 px-10 sub-header" style={{ backgroundColor: "#F7F9FC" }}>
                <div className="w-1/4">
                    <p style={{ fontWeight: "500" }}>{t('reports.usersInReport')}</p>
                    <p>{data.totalUsers}</p>
                </div>
                <div className="w-1/4">
                    <p style={{ fontWeight: "500" }}>{t('reports.groups')}</p>
                    {data.groupNames && data.groupNames.length > 0 ?
                        <p>{data.groupNames.join(", ")}</p> :
                        <p>{data.organizationName}</p>
                    }
                </div>
                <div className="w-1/4">
                    <p style={{ fontWeight: "500" }}>{t('reports.tags')}</p>
                    {data.tagNames && data.tagNames.length > 0 &&
                        <p>{data.tagNames.join(", ")}</p>
                    }
                </div>
                <div className="w-1/4">
                    <p style={{ fontWeight: "500" }}>{t('reports.filters')}</p>
                    {data.filterNames && data.filterNames.length > 0 &&
                        <p>{formatter.format(data.filterNames)}</p>
                    }
                </div>

            </div>
            <div className={`${COMPONENT_CLASS}__section`}>
                <table className={`${COMPONENT_CLASS}__table`}>
                    <thead>
                        <tr>
                            <td>{t('reports.movement.name')}</td>
                            <td>{t('reports.movement.toeTouch')}</td>
                            <td>{t('reports.movement.shoulder')}</td>
                            <td>{t('reports.movement.rotation')}</td>
                            <td>{t('reports.movement.balance')}</td>
                            <td>{t('reports.movement.squat')}</td>
                            <td>{t('reports.movement.shoulderClearing')}</td>
                            <td>{t('reports.movement.spineClearing')}</td>
                            <td>{t('reports.movement.total')}</td>
                            <td>{t('reports.movement.focus')}</td>
                        </tr>
                    </thead>
                    <tbody>
                        {data.users.map((user, index) => (
                            <tr
                                className={`${COMPONENT_CLASS}__table__row`}
                                key={index}>
                                <td>{user.name}</td>
                                <td className={isLow(GroupIds.TOE_TOUCH, user.toeTouchScore) ? "-warning" : ""}>
                                    {formatNumber(user.toeTouchScore)}
                                </td>
                                <td className={isLow(GroupIds.SHOULDER_MOBILITY, user.shoulderMobilityScore) ? "-warning" : ""}>
                                    {formatNumber(user.shoulderMobilityScore)}
                                </td>
                                <td className={isLow(GroupIds.ROTATION, user.rotationScore) ? "-warning" : ""}>
                                    {formatNumber(user.rotationScore)}
                                </td>
                                <td className={isLow(GroupIds.SQUAT, user.squatScore) ? "-warning" : ""}>
                                    {formatNumber(user.squatScore)}
                                </td>
                                <td className={isLow(GroupIds.BALANCE, user.balanceScore) ? "-warning" : ""}>
                                    {formatNumber(user.balanceScore)}
                                </td>
                                <td className={isLow(GroupIds.SHOULDER_CLEARING, undefined, user.shoulderClearing) ? "-warning" : ""}>
                                    {user.shoulderClearing ? t('general.fail') : t('general.pass')}
                                </td>
                                <td className={isLow(GroupIds.SPINE_CLEARING, undefined, user.spineClearing) ? "-warning" : ""}>
                                    {user.spineClearing ? t('general.fail') : t('general.pass')}
                                </td>
                                <td className={isLow(GroupIds.MOVEMENT_HEALTH, user.movementScore) ? "-warning" : ""}>
                                    <span className="font-bold">{formatNumber(user.movementScore)}</span>
                                </td>
                                <td>
                                    <span className="font-light">{user.movementFocus}</span>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div >
    );
}

export default ReportMovement;