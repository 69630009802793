/* eslint-disable no-useless-escape */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import UserService from '../../utilities/services/user-service';
import { User } from '../../models/interfaces/user';
import { createUserWithEmailAndPassword } from "firebase/auth";
import getFirebase from '../../utilities/firebase';
import { useHistory, useParams } from 'react-router-dom';
import { useAuthState } from '../../utilities/contexts/auth-state-context';
import { Modal } from '../../components/modal/modal';
import BaseCardLayout from '../../components/base-card-layout/base-card-layout';
import BaseCardLayoutActions from '../../components/base-card-layout/base-card-layout-actions';
import { MskScore } from '../../models/interfaces/scores/msk-score';
import DeeplinkService from '../../utilities/services/deeplink-service';
import LifestyleAssessmentService from '../../utilities/services/lifestyle-assessment-service/lifestyle-assessment-service';
import { LifestyleAssessment } from '../../models/interfaces/scores/lifestyle-assessment';
import LifestyleScoreService from '../../utilities/services/lifestyle-score-service/lifestyle-score-service';
import { LifestyleScore } from '../../models/interfaces/scores/lifestyle-score';
import MovementScoreService from '../../utilities/services/movement-score-service/movement-score-service';
import { MovementScore } from '../../models/interfaces/scores/movement-score';
import MskScoreServiceAssessments from '../../utilities/services/msk-score-service-assessments/msk-score-service-assessments';
import FirebaseStorage from '../../utilities/firebase-storage';
import { httpsCallable } from 'firebase/functions';
import UserUtilAssessments from '../../utilities/user-util-assessments';
import LoaderAssessments from '../../components/loader-assessments/loader-assessments';
import { SymmioAccessType } from '../../models/enumerations/symmio-access-type';
import { DateTime } from 'luxon';
import { Deeplink } from '../../models/interfaces/deeplink';
import { DeeplinkStatus } from '../../models/enumerations/deeplink-status';
import OrganizationService from '../../utilities/services/organization-service';
import LeadLinksService from '../../utilities/services/lead-links-service';
import { LeadLink } from '../../models/interfaces/lead-link';
import { ReactComponent as CircleIcon } from "../../assets/icons/outline/check-circle-icon.svg";
import { ReactComponent as CheckedIcon } from "../../assets/icons/outline/filled-check-circle-icon.svg";
import { TextTypeInput } from '../../components/forms';
import { EmailTypeInput } from '../../components/forms/email-type-input';
import { CheckboxTypeInput } from '../../components/forms/checkbox-type-input';
import { SubscriptionLink } from '../../models/interfaces/subscription-link';
import SubscriptionLinkService from '../../utilities/services/subscription-link-service';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import MskScoreService from '../../utilities/services/msk-score-service';
import { Utils } from '../../utilities/utils';
import GroupUtil from '../../utilities/group-util';
import { WellnessProgram } from '../../models/interfaces/wellness-program';
import { CorrectiveExerciseUtils } from '../../utilities/corrective-exercise-utils';
import ArticleUtils from '../../utilities/article-utils';
import { GroupIds } from '../../models/enumerations/group-ids';
import WellnessProgramService from '../../utilities/services/wellness-program-service';

const COMPONENT_CLASS = "p-link-create-account";
const LEAD_CAPTURE_LINK_TYPE = "leadCapture";
const SUBSCRIPTION_LINK_TYPE = "subscriptionLink";
const FORM_COMPONENT_CLASS = "p-link-create-account";

export interface LinkLifestyleScreenProps {
    user: User;
    mskScore: MskScore;
    createWellnessProgram: boolean;
}

interface LinkCreateAccountPageParams {
    id: string;
    type?: string;
}

const LinkCreateAccountPage: React.FC<LinkLifestyleScreenProps> = (props) => {
    const { id, type } = useParams<LinkCreateAccountPageParams>();
    const isLeadCaptureLink = type === LEAD_CAPTURE_LINK_TYPE;
    const isSubscriptionLink = type === SUBSCRIPTION_LINK_TYPE;
    const params = new URLSearchParams(window.location.search);
    const email = params.get("email") || '';
    const urlOrgName = params.get("new_studio_name") || '';
    const urlOrgIndustry = params.get("industry");
    const urlCouponId = params.get("couponId");
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            email: email.toLowerCase() ?? '',
            password: '',
            passwordTwo: '',
            termsAndConditions: false
        }
    });
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { state, setState } = useAuthState();
    const { auth } = getFirebase();
    const [passwordError, setPasswordError] = useState("");
    const [EmailError, setEmailError] = useState("");
    const [isLoading] = useState(false);
    const { t, i18n } = useTranslation();
    const [isCreatingAccount, setIsCreatingAccount] = useState(false);
    const [passwordValidation, setPasswordValidation] = useState({ length: false, uppercase: false, lowercase: false, number: false, special: false });
    const [error, setError] = useState(t('pages.landingPage.errors.default_body'));
    const [errorTitle, setErrorTitle] = useState(t('pages.landingPage.errors.default_head'));
    const [modalVisible, setModalVisble] = useState(false);
    const [isCheckedTerms, setIsCheckedTerms] = useState(false);
    const { functions } = getFirebase();
    let history = useHistory();
    let authUser;

    useEffect(() => {
        if (state.authenticated) {
            history.replace("/");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const createUserFromDeeplink = async (email: string) => {
        const callableFunction = httpsCallable(functions, "automatedCreateUserFromDeeplink");

        props.user.preferredLanguage = i18n.language;
        if (urlOrgName) {
            props.user.npeTemp = { orgName: urlOrgName, orgIndustry: urlOrgIndustry, couponId: urlCouponId }
        }
        await callableFunction({
            user: props.user,
            linkId: id,
            email: email,
            isLeadCaptureLink: isLeadCaptureLink,
            isSubscriptionLink: isSubscriptionLink,
        });
        return true;
    }

    const checkDeeplinkValidity = async (deeplink: Deeplink | null) => {
        const nowUtc = DateTime.now().toUTC();
        if (!deeplink) {
            setModalVisble(true);
            setIsCreatingAccount(false);
            throw new Error("Invalid Link");
        }

        const startDate = deeplink.startDate ? DateTime.fromISO(deeplink.startDate) : null;
        const endDate = deeplink.endDate ? DateTime.fromISO(deeplink.endDate) : null;

        const errorFutureHeader = t('pages.landingPage.errors.future_head');
        const errorFutureBody = t('pages.landingPage.errors.future_body', { startDate: startDate?.toLocaleString(DateTime.DATE_FULL) });

        const errorExpiredHeader = t('pages.landingPage.errors.expired_head');
        const errorExpiredBody = t('pages.landingPage.errors.expired_body');

        const errorInactiveHeader = t('pages.landingPage.errors.inactive_head');
        const errorInactiveBody = t('pages.landingPage.errors.inactive_body');

        const errorLimitHeader = t('pages.landingPage.errors.invite_head');
        const errorLimitBody = t('pages.landingPage.errors.invite_body');
        // If start date is a future date
        if (startDate && nowUtc <= startDate.toUTC().startOf("day")) {
            setErrorTitle(errorFutureHeader);
            setError(errorFutureBody);
            setModalVisble(true);
            throw new Error(errorFutureHeader);
        }

        // If end date has passed
        else if (endDate && nowUtc >= endDate.toUTC().endOf("day")) {
            setErrorTitle(errorExpiredHeader);
            setError(errorExpiredBody);
            setModalVisble(true);
            throw new Error(errorExpiredHeader);
        }

        // If the deeplink is not active
        else if (deeplink.status !== DeeplinkStatus.Active) {
            setErrorTitle(errorInactiveHeader);
            setError(errorInactiveBody);
            setModalVisble(true);
            throw new Error(errorInactiveHeader);
        }

        // If the link has reached its usage limit
        else if (deeplink.used && deeplink.limit && deeplink.used >= deeplink.limit) {
            setErrorTitle(errorLimitHeader);
            setError(errorLimitBody);
            setModalVisble(true);
            throw new Error(errorLimitHeader);
        }

        else if (deeplink.organizationId) {
            const org = await OrganizationService.get(deeplink.organizationId);

            // If the organization has reached its user limit
            if (org && org.userLimit && org.userCount && org.userCount >= org.userLimit) {
                setErrorTitle(errorLimitHeader);
                setError(errorLimitBody);
                setModalVisble(true);
                throw new Error(errorLimitHeader);
            }

            // If the organization has reached its license limit
            else if (deeplink.linkType === SymmioAccessType.AppLicense && org && org.mySymmioLicense && org.mySymmioLicenseCount && org.mySymmioLicenseCount >= org.mySymmioLicense) {
                setErrorTitle(errorLimitHeader);
                setError(errorLimitBody);
                setModalVisble(true);
                throw new Error(errorLimitHeader);
            }
        }
    }

    const onSubmit = async (data: any) => {
        setIsCreatingAccount(true);
        const { email, password, passwordTwo } = data;
        if (password !== passwordTwo) {
            setPasswordError(t('baseCard.err_password_npMatch'));
            setIsCreatingAccount(false);
            return;
        }
        const existingUser = await UserService.getBy([{ field: "email", operator: "==", value: email }])
        if (existingUser.length > 0) {
            setEmailError(t('forms.err_emailInUse'));
            setIsCreatingAccount(false);
            return;
        }

        let deepLink: Deeplink | null = null;
        let leadLink: LeadLink | null = null;
        let subscriptionLink: SubscriptionLink | null = null;
        if (isLeadCaptureLink) {
            leadLink = await LeadLinksService.get(id).catch((err: any) => {
                setError(t('pages.linkAccountCreation.err_retrievingLink'));
                setModalVisble(true);
                setIsCreatingAccount(false);
                return null;
            });

            if (!leadLink) {
                setModalVisble(true);
                setIsCreatingAccount(false);
                return;
            }
        }
        else if (isSubscriptionLink) {
            subscriptionLink = await SubscriptionLinkService.get(id).catch((err: any) => {
                setError(t('pages.linkAccountCreation.err_retrievingLink'));
                setModalVisble(true);
                setIsCreatingAccount(false);
                return null;
            });

            if (!subscriptionLink) {
                setModalVisble(true);
                setIsCreatingAccount(false);
                return;
            }
        }
        else {
            deepLink = await DeeplinkService.get(id).catch((err: any) => {
                setError(t('pages.linkAccountCreation.err_retrievingLink'));
                setModalVisble(true);
                setIsCreatingAccount(false);
                return null;
            });
            if (!deepLink) {
                setModalVisble(true);
                setIsCreatingAccount(false);
                return;
            }

            // The main reasons for validating the Deeplink at the very end are:
            // the link limit, the org user limit, and org license limit.
            // The Lead Capture Link and Subscription Link don't have neither of those, so we only validate the deeplink at this stage.
            try {
                await checkDeeplinkValidity(deepLink);
            } catch {
                setModalVisble(true);
                setIsCreatingAccount(false);
                return
            }
        }
        await createUserFromDeeplink(email);
        let users = await UserService.getBy([{
            field: 'email',
            operator: '==',
            value: email.toLowerCase(),
        }])
        if (!users || users.length === 0) {
            // error handling
            setIsCreatingAccount(false);
            return;
        }
        let user = users[0];
        try {
            authUser = await createUserWithEmailAndPassword(
                auth,
                email.toLowerCase(),
                password
            );
        } catch (error: any) {
            // This doesn't seem to be used anywhere
            // setGlobalError("Signup Creating Account", error);

            switch (error.code) {
                case "auth/user-not-found":
                    setEmailError(t('forms.err_emailDoesNotExist'));
                    break;
                case "auth/email-already-in-use":
                    setEmailError(t('forms.err_emailInUse'));
                    break;
                case "auth/invalid-email":
                    setEmailError(t('forms.err_emailInvalid'));
                    break;
                case "auth/weak-password":
                    setPasswordError(
                        t('baseCard.err_password_minChars')
                    );
                    break;
                default:
                    setErrorTitle(t('app.signup.oh'));
                    setError(
                        t('forms.authErrors.custom')
                    );
                    setModalVisble(true);
                    break;
            }
            setIsCreatingAccount(false);
            // We must delete the user that was created
            await UserService.deleteById(user.id!);
            return;
        }
        if (authUser?.user == null) {
            setPasswordError(t('forms.err_userNotFound', { email: email.toLowerCase() }));
            setIsCreatingAccount(false);
            return;
        }

        // Account has been successfully created, we must update the deeplink
        if (deepLink) {
            if (deepLink!.createdUserEmails) {
                deepLink!.createdUserEmails.push(email.toLowerCase());
            } else {
                deepLink!.createdUserEmails = [];
                deepLink!.createdUserEmails.push(email.toLowerCase());
            }

            deepLink!.used = deepLink!.createdUserEmails.length;

            await DeeplinkService.update(deepLink!).catch((error: any) => {
                setError(t('pages.linkAccountCreation.err_updateLink'));
                setModalVisble(true);
                setIsCreatingAccount(false);
                UserService.deleteById(user.id!);
                return;
            });
        }
        else if (leadLink) {
            if (leadLink.createdUserEmails) {
                leadLink.createdUserEmails.push(email);
            } else {
                leadLink.createdUserEmails = [];
                leadLink.createdUserEmails.push(email);
            }

            if (leadLink.leadsTotalCount !== undefined) {
                leadLink.leadsTotalCount += 1;
            }
            else {
                leadLink.leadsTotalCount = 1;
            }

            const currentYear = (new Date()).getUTCFullYear();
            const currentMonthIndex = (new Date()).getUTCMonth();

            if (leadLink.performance && leadLink.performance[currentYear] && leadLink.performance[currentYear][currentMonthIndex]) {
                leadLink.performance[currentYear][currentMonthIndex].leadsCount += 1;
            }
            else {
                leadLink.performance = {
                    ...leadLink.performance,
                    [currentYear]: {
                        [currentMonthIndex]: {
                            clicksCount: 0,
                            leadsCount: 1,
                            starterCount: 0,
                            businessCount: 0,
                            enterpriseCount: 0,
                        }
                    }
                }
            }

            await LeadLinksService.update(leadLink).catch((error: any) => {
                setError(t('pages.linkAccountCreation.err_updateLink'));
                setModalVisble(true);
                setIsCreatingAccount(false);
                UserService.deleteById(user.id!);
                return;
            });
        }
        else if (subscriptionLink) {
            if (subscriptionLink!.createdUserEmails) {
                subscriptionLink!.createdUserEmails.push(email);
            } else {
                subscriptionLink!.createdUserEmails = [];
                subscriptionLink!.createdUserEmails.push(email);
            }

            subscriptionLink!.used = subscriptionLink!.createdUserEmails.length;

            await SubscriptionLinkService.update(subscriptionLink!).catch((error: any) => {
                setError(t('pages.linkAccountCreation.err_updateLink'));
                setModalVisble(true);
                setIsCreatingAccount(false);
                UserService.deleteById(user.id!);
                return;
            });
        }
        // Save each lifestyle assessment individually, this is each object in lifestyle score (you need the new userId) (lifestyleAssessments)
        LifestyleAssessmentService.addOrUpdateRecentByUserId({
            groupId: props.mskScore.lifestyleScore?.behavioralScore?.groupId,
            groupName: props.mskScore.lifestyleScore?.behavioralScore?.groupName,
            percentage: props.mskScore.lifestyleScore?.behavioralScore?.percentage,
            userId: user.id
        } as LifestyleAssessment, user.id || "")
        LifestyleAssessmentService.addOrUpdateRecentByUserId({
            groupId: props.mskScore.lifestyleScore?.bodyCompositionScore?.groupId,
            groupName: props.mskScore.lifestyleScore?.bodyCompositionScore?.groupName,
            percentage: props.mskScore.lifestyleScore?.bodyCompositionScore?.percentage,
            userId: user.id
        } as LifestyleAssessment, user.id || "");
        LifestyleAssessmentService.addOrUpdateRecentByUserId({
            groupId: props.mskScore.lifestyleScore?.breathingScore?.groupId,
            groupName: props.mskScore.lifestyleScore?.breathingScore?.groupName,
            percentage: props.mskScore.lifestyleScore?.breathingScore?.percentage,
            userId: user.id
        } as LifestyleAssessment, user.id || "");
        LifestyleAssessmentService.addOrUpdateRecentByUserId({
            groupId: props.mskScore.lifestyleScore?.injuryHistoryScore?.groupId,
            groupName: props.mskScore.lifestyleScore?.injuryHistoryScore?.groupName,
            percentage: props.mskScore.lifestyleScore?.injuryHistoryScore?.percentage,
            userId: user.id
        } as LifestyleAssessment, user.id || "");
        LifestyleAssessmentService.addOrUpdateRecentByUserId({
            groupId: props.mskScore.lifestyleScore?.nutritionScore?.groupId,
            groupName: props.mskScore.lifestyleScore?.nutritionScore?.groupName,
            percentage: props.mskScore.lifestyleScore?.nutritionScore?.percentage,
            userId: user.id
        } as LifestyleAssessment, user.id || "");
        LifestyleAssessmentService.addOrUpdateRecentByUserId({
            groupId: props.mskScore.lifestyleScore?.physicalActivityScore?.groupId,
            groupName: props.mskScore.lifestyleScore?.physicalActivityScore?.groupName,
            percentage: props.mskScore.lifestyleScore?.physicalActivityScore?.percentage,
            userId: user.id
        } as LifestyleAssessment, user.id || "");
        LifestyleAssessmentService.addOrUpdateRecentByUserId({
            groupId: props.mskScore.lifestyleScore?.sleepScore?.groupId,
            groupName: props.mskScore.lifestyleScore?.sleepScore?.groupName,
            percentage: props.mskScore.lifestyleScore?.sleepScore?.percentage,
            userId: user.id
        } as LifestyleAssessment, user.id || "");
        // Save the overall lifestyle score (lifestyleScores) (just the percentage really)
        LifestyleScoreService.addOrUpdateRecentByUserId({ percentage: props.mskScore.lifestyleScore?.percentage, userId: user.id } as LifestyleScore, user.id || "")
        // Save the movementScore object
        MovementScoreService.addOrUpdateRecentById({
            toeTouchScore: props.mskScore.movementScore?.toeTouchScore,
            shoulderMobilityScore: props.mskScore.movementScore?.shoulderMobilityScore,
            rotationScore: props.mskScore.movementScore?.rotationScore,
            squatScore: props.mskScore.movementScore?.squatScore,
            balanceScore: props.mskScore.movementScore?.balanceScore,
            focusArea: props.mskScore.movementScore?.focusArea,
            hasShoulderClearingPain: props.mskScore.movementScore?.hasShoulderClearingPain,
            hasSpineClearingPain: props.mskScore.movementScore?.hasSpineClearingPain,
            resultDescription: props.mskScore.movementScore?.resultDescription,
            resultLevel: props.mskScore.movementScore?.resultLevel,
            score: props.mskScore.movementScore?.score,
            userId: user.id,
        } as MovementScore, user.id || "")
        // Save the MskScore
        MskScoreServiceAssessments.addOrUpdateRecentById(props.mskScore, user.id || "");
        // Update user if pain exists
        try {
            await UserUtilAssessments.handleNewMskScore(user.id!, {
                mskScore: props.mskScore,
                wasMovementRetake: true,
            });

            user.linkFlowMskGenerated = true;
            await UserService.update(user);
        } catch (err) {
            setIsCreatingAccount(false);
        }

        // Send Account Holder's data
        if (leadLink && leadLink.webhookUrl) {
            await sendDataToWebhook(user, leadLink.webhookUrl, true, true);
        }
        // Send User's data
        else if (deepLink && deepLink.organizationId) {
            const org = await OrganizationService.get(deepLink.organizationId);

            if (org && org.leadLinkId) {
                const orgLeadLink = await LeadLinksService.get(org.leadLinkId).catch((error: any) => {
                    console.error("Could not fetch a Lead Capture Link send data to a webhook: ", error);
                    return null;
                });

                if (orgLeadLink && orgLeadLink.webhookUrl) {
                    await sendDataToWebhook(user, orgLeadLink.webhookUrl, false, false);
                }
            }
        }

        // For some reason after creating user it's supposed to log them in
        // Instead its not logging them in but creating an auth session that's 
        // in a weird state. They can't access any pages and are stuck in login screen
        // but can't login because there is already an auth session active.
        // auth.signOut();
        // setState((state) => ({ ...state, ...{ authenticated: false, user: null } }));

        // This signs them in again similar to the createUserWithEmailAndPassword, they aren't quite logged in,
        // but auth thinks they are. They then can't login until refreshing the page.
        // signInWithEmailAndPassword(auth, data.email, data.password)
        //     .catch((error: any) => {
        //         handleError(error);
        //         setModalVisble(true);
        //     });
        setIsCreatingAccount(false);

        // Create WP if necessary
        if (props.createWellnessProgram && props.user.id) {
            let wellnessProgram: WellnessProgram = {
                createdAtLocal: DateTime.now().toISO(),
                lengthInDays: 0,
                focusAreas: undefined,
                movementFocusAreas: undefined,
                currentDay: 1,
                correctiveExerciseId: undefined,
                focusArticleId: undefined,
                isCurrent: true,
                userId: props.user.id,
                currentProgress: [],
                numOfAdherenceAccountedFor: 0,
            }
            // Create correctiveExercise
            // For some reason old team made it so if there is no extra corrective, still create a corrective of 100 days and no routine
            const ce = await CorrectiveExerciseUtils._createUserCorrectiveExercise(props.user.id);
            const articles = await ArticleUtils.createUserMskArticles(props.user.id, props.mskScore)

            if (ce && ce.hasRoutine) {
                wellnessProgram.correctiveExerciseId = ce.userCorrectiveExercise.id;
                wellnessProgram.lengthInDays = ce.userCorrectiveExercise.routineDays;
            }
            // Create article
            if (articles && articles.id) {
                wellnessProgram.focusArticleId = articles.id;
                let longestArticleLength = 0
                for (let i = 0; i < articles.focusStatuses.length; i++) {
                    let currentCheckArticleLength = 0
                    if (articles.focusStatuses.length === 1) {
                        // User currently cannot get movement_health without a corrective. And the amount of articles
                        // the user gets from movement_health or movement_health_breathing cannot be > length from corrective
                        // Therefore we ignore checking if length is more since it becomes complicated to determine exact 
                        // movement_health articles
                        // IF in the future, movement_health article length can be greater than corrective, we need to change
                        // THIS logic to factor in potential length of numOfArticles from movement_health
                        if (articles.focusStatuses[i].mskFocusAreaGroupId !== GroupIds.MOVEMENT_HEALTH && articles.focusStatuses[i].mskFocusAreaGroupId !== GroupIds.MOVEMENT_HEALTH_BREATHING) {
                            currentCheckArticleLength = (i + 1) + (2 * (articles.focusStatuses[i].numArticles - 1))
                        }
                    }
                    else {
                        if (articles.focusStatuses[i].mskFocusAreaGroupId !== GroupIds.MOVEMENT_HEALTH && articles.focusStatuses[i].mskFocusAreaGroupId !== GroupIds.MOVEMENT_HEALTH_BREATHING) {
                            currentCheckArticleLength = (i + 1) + (articles.focusStatuses.length * (articles.focusStatuses[i].numArticles - 1))
                        }
                    }
                    if (currentCheckArticleLength > longestArticleLength) {
                        longestArticleLength = currentCheckArticleLength;
                    }
                }
                if (longestArticleLength > wellnessProgram.lengthInDays) {
                    wellnessProgram.lengthInDays = longestArticleLength
                }
            }
            if (props.mskScore.focusAreas) {
                wellnessProgram.focusAreas = props.mskScore.focusAreas;
            }
            if (props.mskScore.movementScore && props.mskScore.movementScore.focusArea) {
                wellnessProgram.movementFocusAreas = props.mskScore.movementScore.focusArea
            }

            // Create wellness Program
            const savedWellnessProgram = await WellnessProgramService.add(wellnessProgram);
            props.user.wellnessProgramId = savedWellnessProgram.id
            await UserService.update(props.user);
        }

        if (isLeadCaptureLink) {
            history.push("/lc-assessment-results")
        }
        else if (isSubscriptionLink) {
            history.push("/wellness-results")
        }
        else {
            history.push("/wellness-assessment")
        }
    }


    const onModalDismiss = () => {
        setModalVisble(false);
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleError = (error: any) => {
        if (error?.code == null) {
            setError(
                t('forms.authErrors.custom')
            );
        }

        switch (error.code) {
            case "auth/user-not-found":
                setError(t('forms.authErrors.userNotFound'));
                break;
            case "auth/wrong-password":
                setError(t('forms.authErrors.userNotFound'));
                break;
            case "auth/too-many-requests":
                setError(t('forms.authErrors.tooManyRequests'));
                break;
            case "auth/internal-error":
                setError(t('forms.authErrors.internal'));
                break;
            default:
                setError(t('forms.authErrors.custom'));
                break;
        }
    }

    function handlePasswordValidation(value: any): void {
        setPasswordValidation({
            length: value.length >= 8,
            uppercase: /[A-Z]/.test(value),
            lowercase: /[a-z]/.test(value),
            number: /\d/.test(value),
            special: /[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(value)
        });
    }

    const sendDataToWebhook = async (userToSend: User, webhookUrl: string, hideOrgId: boolean, hideRole: boolean) => {
        try {
            const mskScoreArr = await MskScoreService.getBy([{
                field: "userId",
                operator: "==",
                value: userToSend.id
            }], [{
                field: "created",
                direction: "desc",
            }]);

            if (mskScoreArr && mskScoreArr.length > 0) {
                const userMskScore = mskScoreArr[0];

                let riskLevel = Utils.getMskRiskLevel(userMskScore.riskFactor?.percentage);

                let focusArea1: string | undefined;
                let focusArea2: string | undefined;

                if (userMskScore && userMskScore.focusAreas) {
                    focusArea1 = userMskScore.focusAreas[0] ? userMskScore.focusAreas[0].groupId : undefined;
                    focusArea2 = userMskScore.focusAreas[1] ? userMskScore.focusAreas[1].groupId : undefined;

                    focusArea1 = focusArea1 ? GroupUtil.getName(focusArea1) : undefined;
                    focusArea2 = focusArea2 ? GroupUtil.getName(focusArea2) : undefined;
                }

                const dataToSend = {
                    userId: userToSend.id ?? null,
                    organizationId:
                        hideOrgId ? undefined :
                            userToSend.organizationId ?? null,
                    firstName: userToSend.firstName ?? null,
                    lastName: userToSend.lastName ?? null,
                    email: userToSend.email ?? null,
                    group: userToSend.groupName ?? null,
                    tags: (userToSend.tags && userToSend.tags.length > 0) ? userToSend.tags?.map((tag) => tag.name) : null,
                    wellnessProgramAccess: userToSend.symmioAccess === SymmioAccessType.AppLicense,
                    role:
                        hideRole ? undefined :
                            userToSend && userToSend.roles && userToSend.roles[0] ? userToSend.roles[0] : null,
                    wellnessScore: userMskScore.percentage ?? null,
                    mskRisk: riskLevel ?? null,
                    focusArea1: focusArea1 ?? null,
                    focusArea2: focusArea2 ?? null,
                    movementHealthScore: userMskScore.movementScore?.percentage ?? null,
                    behavioralScore: userMskScore.lifestyleScore?.behavioralScore?.percentage ?? null,
                    bodyCompositionScore: userMskScore.lifestyleScore?.bodyCompositionScore?.percentage ?? null,
                    breathingScore: userMskScore.lifestyleScore?.breathingScore?.percentage ?? null,
                    injuryHistoryScore: userMskScore.lifestyleScore?.injuryHistoryScore?.percentage ?? null,
                    nutritionScore: userMskScore.lifestyleScore?.nutritionScore?.percentage ?? null,
                    physicalActivityScore: userMskScore.lifestyleScore?.physicalActivityScore?.percentage ?? null,
                    sleepScore: userMskScore.lifestyleScore?.sleepScore?.percentage ?? null,
                };

                await axios.post(webhookUrl, dataToSend, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                });
            }
        }
        catch (error) {
            console.error(error);
        }
    }

    return (
        <BaseCardLayout>
            <div className="flex justify-center" style={{ marginTop: "2.3%" }}>
                <LoaderAssessments isLoading={isCreatingAccount} loaderText={t('pages.linkAccountCreation.analyzingResults')} />
                <div className={`${COMPONENT_CLASS}`}>
                    <Modal
                        onClose={onModalDismiss}
                        isOpen={modalVisible}
                        isLoading={isLoading}
                        title={errorTitle}
                        defaultCenterModal={true}
                        submitButtonText={t('app.signup.tryAgain')}
                        onSubmit={() => onModalDismiss()}
                    >
                        <div>
                            <p dangerouslySetInnerHTML={{ __html: error }}></p>
                            <br />
                        </div>
                    </Modal>
                    <div className={`${COMPONENT_CLASS}__container`}>
                        <div className={`${COMPONENT_CLASS}__image hide-on-mobile`}>
                            <img
                                alt="Thank you"
                                src={new FirebaseStorage().getMediaUrl(
                                    "public/complete/movement-complete@2x.png"
                                )}
                            />
                        </div>
                        <div className={`${COMPONENT_CLASS}__info`}>
                            <div className={`${COMPONENT_CLASS}__header-container`}>
                                <h3>{t('misc.thankYou')}</h3>
                                <p>{t('pages.linkAccountCreation.assessmentCompleted')}</p>
                            </div>
                            <h4 className="-light">{t('pages.linkAccountCreation.createAccount')}</h4>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className={`${COMPONENT_CLASS}__content`}>
                                    <div className={`${COMPONENT_CLASS}__field`}>
                                        {errors.email && (
                                            <div
                                                role="alert"
                                                className={`${COMPONENT_CLASS} -error`}>
                                                {t('forms.email_req')}
                                            </div>
                                        )}
                                        {EmailError && (
                                            <div
                                                role="alert"
                                                className={`${COMPONENT_CLASS} -error`}>
                                                {EmailError}
                                            </div>
                                        )}
                                        <EmailTypeInput
                                            id='email'
                                            label={t('forms.email')}
                                            hideLabel={true}
                                            readOnly={!!email}
                                            inputClassName={`c-input__input ${errors.email &&
                                                `${COMPONENT_CLASS} -showerror`
                                                }`}
                                            registerHook={register}
                                            registerOptions={{
                                                required: true,
                                            }} />
                                    </div>
                                    <div className={`${COMPONENT_CLASS}__field`}>
                                        {errors.password && (
                                            <div
                                                role="alert"
                                                className={`${COMPONENT_CLASS} -error`}>
                                                {t('baseCard.err_password_required')}
                                            </div>
                                        )}
                                        {passwordError && (
                                            <div
                                                role="alert"
                                                className={`${COMPONENT_CLASS} -error`}>
                                                {passwordError}
                                            </div>
                                        )}
                                        <TextTypeInput
                                            id='password'
                                            type='password'
                                            label={t('forms.password')}
                                            hideLabel={true}
                                            inputClassName={`c-input__input ${errors.password &&
                                                `${COMPONENT_CLASS} -showerror`
                                                }`}
                                            registerHook={register}
                                            registerOptions={{
                                                required: true,
                                                onChange: (e) => handlePasswordValidation(e.target.value),
                                                pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\-])[A-Za-z\d!@#$%^&*()_+{}\[\]:;<>,.?~\\-]{8,}$/
                                            }}
                                            errorState={errors.password} />
                                    </div>
                                    <div className={`${COMPONENT_CLASS}__field`}>
                                        {errors.passwordTwo && (
                                            <div
                                                role="alert"
                                                className={`${COMPONENT_CLASS} -error`}>
                                                {t('baseCard.err_password_required')}
                                            </div>
                                        )}
                                        {passwordError && (
                                            <div
                                                role="alert"
                                                className={`${COMPONENT_CLASS} -error`}>
                                                {passwordError}
                                            </div>
                                        )}
                                        <TextTypeInput
                                            id='passwordTwo'
                                            type='password'
                                            label={t('forms.confirmPassword')}
                                            hideLabel={true}
                                            inputClassName={`c-input__input ${errors.passwordTwo &&
                                                `${COMPONENT_CLASS} -showerror`
                                                }`}
                                            registerHook={register}
                                            registerOptions={{
                                                required: true,
                                            }}
                                            errorState={errors.passwordTwo} />
                                    </div>
                                    <div style={{ paddingLeft: '1.5em' }}>
                                        {t('forms.passwordTitle')}
                                        <div className={`${FORM_COMPONENT_CLASS}__password_container`}>
                                            <div style={{ width: '50%' }}>
                                                <div className={`${FORM_COMPONENT_CLASS}_item`}>
                                                    {passwordValidation.length ? <div><CheckedIcon style={{ marginRight: '1.5em' }} /></div> : <div><CircleIcon style={{ marginRight: '1.5em' }} /></div>}
                                                    {t('forms.8chars')}
                                                </div>
                                                <div className={`${FORM_COMPONENT_CLASS}_item`}>
                                                    {passwordValidation.lowercase ? <div><CheckedIcon style={{ marginRight: '1.5em' }} /></div> : <div><CircleIcon style={{ marginRight: '1.5em' }} /></div>}
                                                    {t('forms.1lower')} </div>
                                                <div className={`${FORM_COMPONENT_CLASS}_item`}>
                                                    {passwordValidation.uppercase ? <div><CheckedIcon style={{ marginRight: '1.5em' }} /></div> : <div><CircleIcon style={{ marginRight: '1.5em' }} /></div>}
                                                    {t('forms.1upper')} </div>
                                            </div>
                                            <div style={{ width: '50%' }}>
                                                <div className={`${FORM_COMPONENT_CLASS}_item`}>
                                                    {passwordValidation.number ? <div><CheckedIcon style={{ marginRight: '1.5em' }} /></div> : <div><CircleIcon style={{ marginRight: '1.5em' }} /></div>}
                                                    {t('forms.1number')} </div>
                                                <div className={`${FORM_COMPONENT_CLASS}_item`}>
                                                    {passwordValidation.special ? <div><CheckedIcon style={{ marginRight: '1.5em' }} /></div> : <div><CircleIcon style={{ marginRight: '1.5em' }} /></div>}
                                                    {t('forms.1special')} </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`${COMPONENT_CLASS}__field`}>
                                        <div className={`${COMPONENT_CLASS}__terms`} style={{ rowGap: "0px" }}>
                                            {errors.termsAndConditions && (
                                                <div
                                                    role="alert"
                                                    className={`${COMPONENT_CLASS} -error`}
                                                    style={{ gridRow: "1 / 2", gridColumn: "1 / 3" }}>
                                                    {t('forms.tc_agree_val')}
                                                </div>
                                            )}
                                            <CheckboxTypeInput
                                                id='termsAndConditions'
                                                registerHook={register}
                                                registerOptions={{
                                                    required: true,
                                                    onChange: (e) => {
                                                        setIsCheckedTerms(e.target.checked);
                                                    }
                                                }}
                                                // label='I agree to terms and conditions'
                                                checked={isCheckedTerms}
                                                errorState={errors.termsAndConditions} />
                                            <div dangerouslySetInnerHTML={{ __html: t('pages.createAccount.termsAndConditions') }}></div>
                                        </div>

                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ marginTop: "5%", width: "100%" }}>
                <BaseCardLayoutActions backButton={false} submitButton={true} onSubmit={handleSubmit(onSubmit)} submitButtonText={t('pricing.accountPage.next_button')}></BaseCardLayoutActions>
            </div>
        </BaseCardLayout>
    );
}
export default LinkCreateAccountPage;