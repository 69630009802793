import { ReportAssessmentData } from "../models/interfaces/reports/report-assessment-data";
import { ReportComplianceData } from "../models/interfaces/reports/report-compliance-data";
import { ReportDetailData } from "../models/interfaces/reports/report-detail-data";
import { ReportSummaryData } from "../models/interfaces/reports/report-summary-data";
import { UserTag } from "../models/interfaces/user-tag";
import { MskScore } from "../models/interfaces/msk-score";
import { DateTime } from "luxon";
import { AssessmentUtils } from "../utilities/assessments/assessment-utils";
import { ReportType } from "../models/enumerations/report-type";
import ReportService from "../utilities/services/report-service";
import { Report } from "../models/interfaces/report";
import { closeSnackbar, enqueueSnackbar, SnackbarKey } from "notistack";
import i18n from "../i18n";
import MskScoreUtil from "./msk-score-util";
import UserService from "../utilities/services/user-service";
import { User } from "../models/interfaces/user";
import { GroupIds } from "../models/enumerations/group-ids";

interface DetailRow {
    name: string;
    email: string;
    age: number | string;
    group: string;
    tags: string;
    mskScore: number | string;
    mskRisk: string;
    pain: string;
    focus1: string;
    focus2: string;
}

interface ScoreRow {
    name: string;
    email: string;
    age: number | string;
    group: string;
    tags: string;
    movementScore: number | string;
    breathingScore: number | string;
    injuryScore: number | string;
    behavioralScore: number | string;
    activityScore: number | string;
    bodyCompScore: number | string;
    sleepScore: number | string;
    nutritionScore: number | string;
    mskScore: number | string;
}

interface SummaryRow {
    organization: string;
    month: string;
    users: number | string;
    currentMsk: number | string;
    month8: number | string;
    month7: number | string;
    month6: number | string;
    month5: number | string;
    month4: number | string;
    month3: number | string;
    month2: number | string;
    month1: number | string;
    scores0to9: number | string;
    scores10to19: number | string;
    scores20to29: number | string;
    scores30to39: number | string;
    scores40to49: number | string;
    scores50to59: number | string;
    scores60to69: number | string;
    scores70to79: number | string;
    scores80to89: number | string;
    scores90plus: number | string;
    highRisk: number | string;
    moderateRisk: number | string;
    slightRisk: number | string;
    lowRisk: number | string;
    movementHealth: number | string;
    breathingQuality: number | string;
    injuryHistory: number | string;
    behavioralHealth: number | string;
    physicalActivity: number | string;
    bodyComposition: number | string;
    sleepQuality: number | string;
    nutritionalAwareness: number | string;
    toeTouch: number | string;
    balanceAndReach: number | string;
    shoulderMobility: number | string;
    shoulderClearing: number | string;
    rotation: number | string;
    spineClearing: number | string;
    squat: number | string;
    pain: number | string;
}

interface ComplianceRow {
    timeframe: string;
    group: string;
    tags: string;
    users: number | string;
    activeUsers: number | string;
    inactiveUsers: number | string;
    pendingUsers: number | string;
    loggedIntoApp: number | string;
    takenAssessment: number | string;
    completed50PercentOfTasks: number | string;
    pendingAssessment: string;
    pendingInitialAssessment: string;
}

export default class ReportDataUtil {
    // -----------------------------------------------------------------------------------------
    // #region Public Methods
    // -----------------------------------------------------------------------------------------

    public static generateAssessment(data: ReportAssessmentData) {
        // TODO - no csv example provided
    }

    public static generateCompliance(data: ReportComplianceData) {
        const result: ComplianceRow[] = [];
        result.push({
            timeframe: `${data.reportStartDate} - ${data.reportDate}`,
            group: '',
            tags: '',
            users: data.totalUsers,
            activeUsers: data.activeUsers,
            inactiveUsers: data.inactiveUsers,
            pendingUsers: data.pendingUsers,
            loggedIntoApp: data.usersLoggedIn,
            takenAssessment: data.usersAssessment,
            completed50PercentOfTasks: data.usersCompletedTasks,
            pendingAssessment: '',
            pendingInitialAssessment: '',
        });

        const emptyRow = {
            timeframe: '',
            group: '',
            tags: '',
            users: '',
            activeUsers: '',
            inactiveUsers: '',
            pendingUsers: '',
            loggedIntoApp: '',
            takenAssessment: '',
            completed50PercentOfTasks: '',
            pendingAssessment: '',
            pendingInitialAssessment: '',
        };

        data.pendingLoginUsers.forEach((pl, i) => {
            if (result.length >= i + 1) {
                result[i].pendingInitialAssessment = pl;
            } else {
                const newRow = { ...emptyRow };
                newRow.pendingInitialAssessment = pl;
                result.push(newRow);
            }
        });

        data.pendingAssessmentUsers.forEach((pa, i) => {
            if (result.length >= i + 1) {
                result[i].pendingAssessment = pa;
            } else {
                const newRow = { ...emptyRow };
                newRow.pendingAssessment = pa;
                result.push(newRow);
            }
        })

        data.groupNames?.forEach((gn, i) => {
            if (result.length >= i + 1) {
                result[i].group = gn;
            } else {
                const newRow = { ...emptyRow };
                newRow.group = gn;
                result.push(newRow);
            }
        });

        data.tagNames?.forEach((tn, i) => {
            if (result.length >= i + 1) {
                result[i].tags = tn;
            } else {
                const newRow = { ...emptyRow };
                newRow.tags = tn;
                result.push(newRow);
            }
        });

        return this.generateComplianceCsv(result);
    }

    private static generateComplianceCsv(data: ComplianceRow[]) {
        let csvStr = '';
        const headers = i18n.t('utils.reportData.complianceHeaders') + '\n'
        csvStr += headers;
        data.forEach((r) => {
            let row = '';
            row += r.timeframe + ',';
            row += r.group + ',';
            row += r.tags + ',';
            row += r.users + ',';
            row += r.activeUsers + ',';
            row += r.inactiveUsers + ',';
            row += r.pendingUsers + ',';
            row += r.loggedIntoApp + ',';
            row += r.takenAssessment + ',';
            row += r.completed50PercentOfTasks + ',';
            row += ' ,';
            row += r.pendingAssessment + ',';
            row += r.pendingInitialAssessment + '\n';
            csvStr += row;
        });
        return csvStr;
    }

    public static generateDetail(data: ReportDetailData) {
        const result: DetailRow[] = [];

        const getMskRisk = (r: string | undefined) => {
            switch (r) {
                case 'Health':
                    return i18n.t('general.mskRisk.high');
                case 'Wellness':
                    return i18n.t('general.mskRisk.moderate');
                case 'Fitness':
                    return i18n.t('general.mskRisk.slight');
                case 'Performance':
                    return i18n.t('general.mskRisk.low');
                default:
                    return '';
            }
        }

        data.users.forEach((u) => {
            const userTags = this.stringTags(u.tags);
            const row: DetailRow = {
                name: u.name,
                email: u.email,
                age: u.dob ? this.getAgeFromDob(u.dob) : '',
                group: u.groupName ?? '',
                tags: userTags,
                mskScore: u.mskScore ?? '',
                mskRisk: getMskRisk(u.healthCategory),
                pain: u.inPain === true ? 'yes' : 'no',
                focus1: '',
                focus2: '',
            };

            if (u.focusAreas && u.focusAreas.length > 0) {
                row.focus1 = u.focusAreas[0];
                if (u.focusAreas.length >= 2) {
                    row.focus2 = u.focusAreas[1];
                }
            }

            result.push(row);
        })

        return this.generateDetailCsv(result);
    }

    private static generateDetailCsv(data: DetailRow[]) {
        let csvStr = '';
        const headers = i18n.t('utils.reportData.detailHeaders') + '\n'
        csvStr += headers;
        data.forEach((r) => {
            let row = '';
            row += r.name + ',';
            row += r.email + ',';
            row += r.age + ',';
            row += r.group + ',';
            row += r.tags + ',';
            row += r.mskScore + ',';
            row += r.mskRisk + ',';
            row += r.pain + ',';
            row += r.focus1 + ',';
            row += r.focus2 + '\n';
            csvStr += row;
        });
        return csvStr;
    }

    public static generateScore(data: ReportDetailData) {
        const result: ScoreRow[] = [];

        data.users.forEach((u) => {
            const userTags = this.stringTags(u.tags);
            const row: ScoreRow = {
                name: u.name,
                email: u.email,
                age: u.dob ? this.getAgeFromDob(u.dob) : '',
                group: u.groupName ?? '',
                tags: userTags,
                movementScore: u.movementScore ?? '',
                breathingScore: u.breathingScore ?? '',
                injuryScore: u.injuryHistoryScore ?? '',
                behavioralScore: u.behavioralScore ?? '',
                activityScore: u.activityScore ?? '',
                bodyCompScore: u.bodyCompScore ?? '',
                sleepScore: u.sleepScore ?? '',
                nutritionScore: u.nutritionScore ?? '',
                mskScore: u.mskScore ?? '',
            };

            // if (u.tags && u.tags.length > 0) {
            //     let tagString = '';
            //     u.tags.forEach((t: string, i) => tagString.concat(t, i !== tags.length ? ' - ' : ''));
            //     row.tags = tagString;
            // }

            result.push(row);
        })

        return this.generateScoreCsv(result);
    }

    private static generateScoreCsv(data: ScoreRow[]) {
        let csvStr = '';
        const headers = i18n.t('utils.reportData.scoreHeaders') + '\n'
        csvStr += headers;
        data.forEach((r) => {
            let row = '';
            row += r.name + ',';
            row += r.email + ',';
            row += r.age + ',';
            row += r.group + ',';
            row += r.tags + ',';
            row += r.movementScore + ',';
            row += r.breathingScore + ',';
            row += r.injuryScore + ',';
            row += r.behavioralScore + ',';
            row += r.activityScore + ',';
            row += r.bodyCompScore + ',';
            row += r.sleepScore + ',';
            row += r.nutritionScore + ',';
            row += r.mskScore + '\n';
            csvStr += row;
        });
        return csvStr;
    }

    public static generateSummary(data: ReportSummaryData) {
        const row: SummaryRow = {
            organization: data.organizationName ?? '',
            month: data.reportDate ? this.getMonthString(data.reportDate) : '',
            users: data.totalUsers,
            currentMsk: '',
            month8: '',
            month7: '',
            month6: '',
            month5: '',
            month4: '',
            month3: '',
            month2: '',
            month1: '',
            scores0to9: data.scoreBreakdown ? data.scoreBreakdown[0].count : '',
            scores10to19: data.scoreBreakdown ? data.scoreBreakdown[1].count : '',
            scores20to29: data.scoreBreakdown ? data.scoreBreakdown[2].count : '',
            scores30to39: data.scoreBreakdown ? data.scoreBreakdown[3].count : '',
            scores40to49: data.scoreBreakdown ? data.scoreBreakdown[4].count : '',
            scores50to59: data.scoreBreakdown ? data.scoreBreakdown[5].count : '',
            scores60to69: data.scoreBreakdown ? data.scoreBreakdown[6].count : '',
            scores70to79: data.scoreBreakdown ? data.scoreBreakdown[7].count : '',
            scores80to89: data.scoreBreakdown ? data.scoreBreakdown[8].count : '',
            scores90plus: data.scoreBreakdown ? data.scoreBreakdown[9].count : '',
            highRisk: data.riskFactorCodeCounts[0].count,
            moderateRisk: data.riskFactorCodeCounts[1].count,
            slightRisk: data.riskFactorCodeCounts[2].count,
            lowRisk: data.riskFactorCodeCounts[3].count,
            movementHealth: data.focusAreas ? data.focusAreas.find((x) => x.groupId === GroupIds.MOVEMENT_HEALTH)?.count ?? 0 : '',
            breathingQuality: data.focusAreas ? data.focusAreas.find((x) => x.groupId === GroupIds.BREATHING)?.count ?? 0 : '',
            injuryHistory: data.focusAreas ? data.focusAreas.find((x) => x.groupId === GroupIds.INJURY_HISTORY)?.count ?? 0 : '',
            behavioralHealth: data.focusAreas ? data.focusAreas.find((x) => x.groupId === GroupIds.BEHAVIORAL_HEALTH)?.count ?? 0 : '',
            physicalActivity: data.focusAreas ? data.focusAreas.find((x) => x.groupId === GroupIds.PHYSICAL_ACTIVITY)?.count ?? 0 : '',
            bodyComposition: data.focusAreas ? data.focusAreas.find((x) => x.groupId === GroupIds.BODY_COMPOSITION)?.count ?? 0 : '',
            sleepQuality: data.focusAreas ? data.focusAreas.find((x) => x.groupId === GroupIds.SLEEP)?.count ?? 0 : '',
            nutritionalAwareness: data.focusAreas ? data.focusAreas.find((x) => x.groupId === GroupIds.NUTRITION)?.count ?? 0 : '',
            toeTouch: data.movementFocusAreas ? data.movementFocusAreas.find((x) => x.groupId === GroupIds.TOE_TOUCH)?.count ?? 0 : '',
            balanceAndReach: data.movementFocusAreas ? data.movementFocusAreas.find((x) => x.groupId === GroupIds.BALANCE)?.count ?? 0 : '',
            shoulderMobility: data.movementFocusAreas ? data.movementFocusAreas.find((x) => x.groupId === GroupIds.SHOULDER_MOBILITY)?.count ?? 0 : '',
            shoulderClearing: data.movementFocusAreas ? data.movementFocusAreas.find((x) => x.groupId === GroupIds.SHOULDER_CLEARING)?.count ?? 0 : '',
            rotation: data.movementFocusAreas ? data.movementFocusAreas.find((x) => x.groupId === GroupIds.ROTATION)?.count ?? 0 : '',
            spineClearing: data.movementFocusAreas ? data.movementFocusAreas.find((x) => x.groupId === GroupIds.SPINE_CLEARING)?.count ?? 0 : '',
            squat: data.movementFocusAreas ? data.movementFocusAreas.find((x) => x.groupId === GroupIds.SQUAT)?.count ?? 0 : '',
            pain: data.movementFocusAreas ? data.movementFocusAreas.find((x) => x.groupId === GroupIds.PAIN)?.count ?? 0 : '',
        }

        if (data.history && data.history.length > 0) {
            const len = data.history.length;
            row.currentMsk = data.history[0].percent;
            if (len > 1) row.month8 = data.history[1].percent === -1 ? '' : data.history[1].percent;
            if (len > 2) row.month7 = data.history[2].percent === -1 ? '' : data.history[2].percent;
            if (len > 3) row.month6 = data.history[3].percent === -1 ? '' : data.history[3].percent;
            if (len > 4) row.month5 = data.history[4].percent === -1 ? '' : data.history[4].percent;
            if (len > 5) row.month4 = data.history[5].percent === -1 ? '' : data.history[5].percent;
            if (len > 6) row.month3 = data.history[6].percent === -1 ? '' : data.history[6].percent;
            if (len > 7) row.month2 = data.history[7].percent === -1 ? '' : data.history[7].percent;
            if (len > 8) row.month1 = data.history[8].percent === -1 ? '' : data.history[8].percent;
        }

        return this.generateSummaryCsv([row]);
    }

    private static generateSummaryCsv(data: SummaryRow[]) {
        let csvStr = '';
        const headers = i18n.t('utils.reportData.summaryHeaders') + '\n'
        csvStr += headers;
        data.forEach((r) => {
            let row = '';
            row += r.organization + ',';
            row += r.month + ',';
            row += r.users + ',';
            row += r.currentMsk + ',';
            row += r.month8 + ',';
            row += r.month7 + ',';
            row += r.month6 + ',';
            row += r.month5 + ',';
            row += r.month4 + ',';
            row += r.month3 + ',';
            row += r.month2 + ',';
            row += r.month1 + ',';
            row += r.scores0to9 + ',';
            row += r.scores10to19 + ',';
            row += r.scores20to29 + ',';
            row += r.scores30to39 + ',';
            row += r.scores40to49 + ',';
            row += r.scores50to59 + ',';
            row += r.scores60to69 + ',';
            row += r.scores70to79 + ',';
            row += r.scores80to89 + ',';
            row += r.scores90plus + ',';
            row += r.highRisk + ',';
            row += r.moderateRisk + ',';
            row += r.slightRisk + ',';
            row += r.lowRisk + ',';
            row += r.movementHealth + ',';
            row += r.breathingQuality + ',';
            row += r.injuryHistory + ',';
            row += r.behavioralHealth + ',';
            row += r.physicalActivity + ',';
            row += r.bodyComposition + ',';
            row += r.sleepQuality + ',';
            row += r.nutritionalAwareness + ',';
            row += r.toeTouch + ',';
            row += r.balanceAndReach + ',';
            row += r.shoulderMobility + ',';
            row += r.shoulderClearing + ',';
            row += r.rotation + ',';
            row += r.spineClearing + ',';
            row += r.squat + ',';
            row += r.pain + '\n';
            csvStr += row;
        });
        return csvStr;
    }

    private static async generateWellnessReport(userId: string, organizationId: string, type: number, mskScore?: MskScore) {
        const date = DateTime.now().toISODate();

        const report: Report = {
            dateEnd: date,
            dateStart: date,
            emailResults: false,
            organizationId: organizationId,
            status: "scheduled",
            type: type,
            userId: userId,
            mskScoreId: mskScore ? mskScore.id : undefined,
            language: i18n.language,
        };

        const user = await UserService.get(userId);

        const scheduledReport = await ReportService.save(
            report,
            user
        );

        if (!scheduledReport.id) {
            return;
        }

        const reportUnsubscribe = ReportService.getSnapshot(
            scheduledReport.id,
            (r: Report) => {
                if (r.status === "complete" && r.url) {
                    reportUnsubscribe();

                    //Loads in the System browser
                    window.open(r.url);
                }
                if (r.status === "error") {
                    reportUnsubscribe();
                }
            }
        );
    }

    public static async downloadWellness(user: User | null | undefined, mskScore: MskScore | null | undefined) {
        if (!user || !user.id || !mskScore) return;
        const userInFirebase = await UserService.get(user.id);
        if (!userInFirebase || !userInFirebase.organizationId) return;
        const latestOrgId = userInFirebase.organizationId;
        const userId = userInFirebase.id;

        if (!userId) {
            return;
        }
        // Get assessmentResponses
        const assessmentResponseCheck = await AssessmentUtils.fetchAssessmentResponsesById(userId)
        // Get latestMskScore
        const validMskScore = MskScoreUtil.checkMskScore(mskScore);
        let type = ReportType.Assessment
        if ((assessmentResponseCheck && assessmentResponseCheck.length > 7) || validMskScore) {
            type = ReportType.Wellness
        }
        const date = DateTime.utc().toISO();
        const report = await ReportService.getBy(
            [
                {
                    field: "userId",
                    operator: "==",
                    value: userId,
                },
                {
                    field: "organizationId",
                    operator: "==",
                    value: latestOrgId,
                },
                {
                    field: "status",
                    operator: "==",
                    value: "complete",
                },
                {
                    field: "type",
                    operator: "==",
                    value: type,
                },
                {
                    field: "expiration",
                    operator: ">",
                    value: date,
                },
                {
                    field: "mskScoreId",
                    operator: "==",
                    value: mskScore.id
                }
            ], [
            {
                field: "expiration",
                direction: "desc",
            },
            {
                field: "updated",
                direction: "desc",
            }]);

        let downloadSnackbar: SnackbarKey;

        if (report.length <= 0) {
            downloadSnackbar = enqueueSnackbar(i18n.t('general.reportDownload.pleaseWait').toString(), { variant: "info", autoHideDuration: 30000 });

            if (validMskScore) {
                this.generateWellnessReport(userId, latestOrgId, type, mskScore);
            }
            else {
                this.generateWellnessReport(userId, latestOrgId, type);
            }
            return;
        }

        if (!report[0].id) {
            return;
        }

        const reportUnsubscribe = ReportService.getSnapshot(
            report[0].id,
            (r: Report) => {
                if (r.status === "complete" && r.url) {
                    reportUnsubscribe();

                    //Loads in the System browser
                    closeSnackbar(downloadSnackbar);
                    window.open(r.url);
                }
                if (r.status === "error") {
                    closeSnackbar(downloadSnackbar);
                    reportUnsubscribe();
                }
            }
        );
    }

    private static getAgeFromDob(dob: string) {
        const today = new Date();
        const dobDate = new Date(dob);
        let age = today.getFullYear() - dobDate.getFullYear();
        const m = today.getMonth() - dobDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < dobDate.getDate())) {
            age -= 1;
        }
        return age;
    }

    private static getMonthString(date: string) {
        const m = new Date(date).getMonth();

        const months = [
            i18n.t('utils.months.january'),
            i18n.t('utils.months.february'),
            i18n.t('utils.months.march'),
            i18n.t('utils.months.april'),
            i18n.t('utils.months.may'),
            i18n.t('utils.months.june'),
            i18n.t('utils.months.july'),
            i18n.t('utils.months.august'),
            i18n.t('utils.months.september'),
            i18n.t('utils.months.october'),
            i18n.t('utils.months.november'),
            i18n.t('utils.months.december'),
        ];

        return months[m + 1];
    }

    private static stringTags(tags: UserTag[]) {
        if (tags.length < 1) {
            return '';
        }

        let tagStr = '';

        tags.forEach((t, i) => {
            if (i + 1 < tags.length) {
                tagStr += `${t.name} / `;
            }
            tagStr += t.name;
        });

        return tagStr;
    }

    // #endregion Public Methods
}
