/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  PaymentElement
} from '@stripe/react-stripe-js';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import { useAuthState } from "../../utilities/contexts/auth-state-context";
import { PaymentCycle } from "../../models/enumerations/payment-cycle";
import { Loader } from "../loader/loader";
import OrganizationSubscriptionService from "../../utilities/services/organization-subscription-service";
import { WarningItem } from "../warning-item/warning-item";
import { Button } from "../button/button";
import { Tag as TagComponent } from "../tag/tag";
import OrganizationService from "../../utilities/services/organization-service";
import { PlanType } from "../../models/enumerations/plan-type";
import { UserLimit } from "../../models/enumerations/user-limit";
import { LicenseLimit } from "../../models/enumerations/license-limit";
import FunctionService from "../../utilities/services/function-service";
import moment from "moment";
import { PlanId } from "../../models/enumerations/plan-id";
import { AddOnId } from "../../models/enumerations/add-on-id";
import { User } from "../../models/interfaces/user";
import { Organization } from "../../models/interfaces/organization";
import { TextTypeInput } from "../forms";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { PlanTypeLabels } from "../../models/plan-type";
import { AppContentType } from "../../models/enumerations/app-content-type";
import { AppContent } from "../../models/interfaces/app-content";
import { useParams } from 'react-router-dom';
import { OrganizationStatus } from "../../models/enumerations/organization-status";
import LeadLinksService from "../../utilities/services/lead-links-service";
const NPE_FLOW_LEAD_TYPE = "npeLead";

interface CheckoutFormProps {
  setUpgradeModalOpen?: Function;
  isCreating?: boolean;
  isUpgrading?: boolean;
  isRetrying?: boolean;
  onChangePlan?: () => void,
  returnUrl: string;
  paymentFailed: Function;
  paymentSuccessful: Function;
  isValidating: Function;
  paymentCycle?: PaymentCycle;
  purchasePrice?: number;
  checkoutFlag?: number;
  products: any[];
  onInfoCreate?: (user: User, organization: Organization) => Promise<string>;
}
interface ValidationErrors {
  [key: string]: string;
}
interface NPEPaymentPageParams {
  npeType: string;
  id: string;
}
const CheckoutForm: React.FC<CheckoutFormProps> = ({ setUpgradeModalOpen, isCreating, products, isUpgrading, isRetrying, onChangePlan, paymentSuccessful, paymentFailed, returnUrl, paymentCycle, purchasePrice, checkoutFlag, onInfoCreate, isValidating }) => {
  const COMPONENT_CLASS = "c-checkoutform";
  const FORM_COMPONENT_CLASS = "p-link-create-account";

  const { t, i18n } = useTranslation();
  const { state } = useAuthState();
  const stripe = useStripe();
  const stripe2 = require('stripe')(process.env.REACT_APP_STRIPE_SECRET_KEY);
  const [clientSecret, setClientSecret] = useState('');
  const elements = useElements();
  const [customer, setCustomer] = useState<any>();
  const [user, setUser] = useState<any>();
  const [product, setProduct] = useState<any>();
  const [paymentFailedBanner, setPaymentFailedBanner] = useState<boolean>(false);
  const [newPrice, setNewPrice] = useState<any>();
  const [totalPrice, setTotalPrice] = useState<number>();
  const [newPriceNext, setNewPriceNext] = useState<any>();
  const [coupons, setCoupons] = useState([] as any[]);
  const [subscriptionCoupon, setSubscriptionCoupon] = useState<string>();
  const [allCoupons, setAllCoupons] = useState<any>();
  const [couponValue, setCouponValue] = useState<any>();
  //const stripeEl = new Stripe('sk_test_51Ildh6HoJbt1xrgTackwpgLScozEdruu3qOmzOCebF4LQkQGbSfHs8RYh6xdjr5ORh6BBgeUOpffif21je8smjOM00PLz4GXdQ');
  // const elementsStripe = stripe!.elements({ clientSecret, appearance });
  // const paymentElement = elementsStripe.create("payment", options);
  // paymentElement.mount('#payment-element');
  const [isLoading, setIsLoading] = useState(true);
  const [discountError, setDiscountError] = useState<string>('');
  const [couponExists, setCouponExists] = useState<boolean>(true);
  const methods = useForm<any>();
  const { npeType, id } = useParams<NPEPaymentPageParams>();
  const isNPELink = npeType === NPE_FLOW_LEAD_TYPE;
  const params = new URLSearchParams(window.location.search);
  const couponId = state.user?.npeTemp?.couponId ? state.user.npeTemp.couponId : params.get("couponId");

  const {
    register,
  } = methods;

  useEffect(() => {
    if (isCreating && checkoutFlag && checkoutFlag > 0) {
      handleSubscriptionStart();
    }
  }, [checkoutFlag]);

  useEffect(() => {
    if (isCreating) {
      setUser(state.temp.user);
      setProduct(state.temp.product);
    }
    else if (!isCreating) {
      setUser(state.user);
      setProduct(products.find((item: any) => item.product.id === PlanId.Business).product)
    }

  }, [isCreating, state.temp]);

  let priceToShow = "";

  if (isUpgrading && products && products.length > 0 && product) {
    priceToShow = paymentCycle === PaymentCycle.Yearly ? (parseFloat(products.find((item: any) => item.product.id === PlanId.Business).prices.find((item: any) => item.data.interval !== "month").data.unit_amount) / 100).toFixed(2) : (parseFloat(products.find((item: any) => item.product.id === PlanId.Business).prices.find((item: any) => item.data.interval === "month").data.unit_amount) / 100).toFixed(2);
  }

  if (elements) {
    const element = elements!.getElement('payment')
    if (element) {
      element.on('ready', () => {
        setIsLoading(false);
      })
    }

  }
  else {
  }

  useEffect(() => {
    const confirmIntent = (async () => {
      setIsLoading(true);
      isValidating(true);
      if (stripe && elements) {
        // Use the clientSecret and Elements instance to confirm the setup
        const { error, setupIntent } = await stripe.confirmSetup({
          elements,
          clientSecret,
          confirmParams: {
            return_url: `${window.location.origin}${returnUrl}`,
          },
          redirect: "if_required"
        });
        if (error) {
          setIsLoading(false);
          isValidating(false);
          setPaymentFailedBanner(true);
          paymentFailed(true);
        }
        else {
          // Update customer to use the new payment method as their default
          await stripe2.customers.update(
            customer.data ? customer.data[0].id : customer.id,
            {
              invoice_settings: {
                default_payment_method: setupIntent.payment_method,
              },
            }
          );
          // Create Plan Subscription
          await stripe2.subscriptions.create({
            customer: customer.data ? customer.data[0].id : customer.id,
            items: [
              {
                // This is currently hard-coded
                // price: isCreating ? product.priceId :
                price: `${paymentCycle === PaymentCycle.Yearly ? products.find((item: any) => item.product.id === PlanId.Business).prices.find((item: any) => item.data.interval !== "month").id : products.find((item: any) => item.product.id === PlanId.Business).prices.find((item: any) => item.data.interval === "month").id}`,
              }
            ],
            coupon: subscriptionCoupon,
            payment_behavior: 'error_if_incomplete',
            payment_settings: { save_default_payment_method: 'off' },
            expand: ['latest_invoice.payment_intent'],
            // TODO: Add Stripe type to stripe2 after release to avoid errors.
          }).then(async (data: any) => {
            let nextPaymentDate = calculateNextPayment(product?.priceInfo);
            let paymentInfo = { nextPaymentDate: nextPaymentDate, planCost: `$${purchasePrice ? purchasePrice.toFixed(2) : totalPrice!.toFixed(2)}`, totalChargedAmount: newPrice ? `$${newPrice.toFixed(2)}` : `$${purchasePrice ? purchasePrice.toFixed(2) : totalPrice!.toFixed(2)}` };
            let couponDiscountAmount = '';
            let copyOfTotalDiscount = 0;
            if (coupons.length > 0) {
              if (coupons[0].coupon.amount_off) {
                couponDiscountAmount = `$${(coupons[0].coupon.amount_off / 100).toFixed(2)}`;
                copyOfTotalDiscount = (coupons[0].coupon.amount_off / 100)
              } else {
                couponDiscountAmount = `$${((Number(purchasePrice ? purchasePrice : totalPrice) * coupons[0].coupon.percent_off) / 100).toFixed(2)}`;
                copyOfTotalDiscount = ((Number(purchasePrice ? purchasePrice : totalPrice) * coupons[0].coupon.percent_off) / 100);
              }
            }
            const copyOfTotalCharged = newPrice ? newPrice : purchasePrice ? purchasePrice : totalPrice;
            const updatedTotal = copyOfTotalCharged - copyOfTotalDiscount;
            paymentInfo = { ...paymentInfo, totalChargedAmount: `$${updatedTotal.toFixed(2)}` };
            const couponInfo = coupons.length > 0 ? { couponCode: coupons[0].code, couponDiscount: coupons[0].coupon.amount_off ? `$${coupons[0].coupon.amount_off / 100}` : `${coupons[0].coupon.percent_off}%`, couponDiscountAmount: couponDiscountAmount } : null;
            if (paymentCycle === PaymentCycle.Yearly) {
              FunctionService.sendBusinessYearlyConfirmationEmail(user, paymentInfo, couponInfo);
            } else {
              FunctionService.sendBusinessMonthlyConfirmationEmail(user, paymentInfo, couponInfo);
            }
            let org = await OrganizationService.get(isCreating ? state.temp.organization.id : state.organization.id!);
            // We separate subscriptions by plan & add-ons. We need to keep track of which subscription is which.
            // Therefore, we are going to save the id of the subscriptions in their respective property.
            // Get Organization Subscription
            let orgSub = await OrganizationSubscriptionService.getByOrganizationId(isCreating ? state.temp.organization.id : state.organization.id!)
            const startDate = new Date();
            const dateInSeconds = data.current_period_end;
            const renewDate = new Date(dateInSeconds * 1000);
            if (!orgSub || orgSub?.length === 0) {
              setIsLoading(false);
              isValidating(false);
              return;
            }

            if (isCreating && org && org.id && orgSub) {
              orgSub[0].organizationId = org.id;
              orgSub[0].startDate = startDate.toISOString();
              orgSub[0].renewDate = renewDate.toISOString();
              orgSub[0].userLimit = UserLimit.Business;
              orgSub[0].price = (data.plan && data.plan.amount) ? (data.plan.amount / 100) : 0;
              orgSub[0].stripePlanSubscription = data.id;
              orgSub[0].plan = {
                label: PlanTypeLabels[PlanType.Business],
                value: PlanType.Business,
              };
              orgSub[0].paymentCycle = {
                label: PaymentCycle[paymentCycle === PaymentCycle.Yearly ? PaymentCycle.Yearly : PaymentCycle.Monthly],
                value: paymentCycle === PaymentCycle.Yearly ? PaymentCycle.Yearly : PaymentCycle.Monthly,
              };
              orgSub[0].mySymmioLicense = LicenseLimit.Business;
              await OrganizationSubscriptionService.save(orgSub[0]);
            }

            // Get Organization
            org!.plan = { value: PlanType.Business, label: PlanTypeLabels[PlanType.Business] };
            org!.userLimit = UserLimit.Business;
            org!.mySymmioLicense = LicenseLimit.Business;
            await OrganizationService.save(org!)

            if (!isCreating) {
              orgSub[0].price = (data.plan && data.plan.amount) ? (data.plan.amount / 100) : 0;
              orgSub[0].stripePlanSubscription = data.id;
              orgSub[0].startDate = startDate.toISOString();
              orgSub[0].renewDate = renewDate.toISOString();

              await OrganizationSubscriptionService.save(orgSub[0]);
            }

            // Create Add-On Placeholder Subscription
            await stripe2.subscriptions.create({
              customer: customer.data ? customer.data[0].id : customer.id,
              items: [
                {
                  // This is currently hard-coded
                  price: products.find((item) => item.product.id === AddOnId.User).prices.find((item: any) => item.data.active === true).id,
                  quantity: 0
                },
                {
                  // This is currently hard-coded
                  price: products.find((item) => item.product.id === AddOnId.License).prices.find((item: any) => item.data.active === true).id,
                  quantity: 0
                }
                // When we provide the ability to add additional organization, 
                // we can use this code and use the dynamic organizationId within the firebase database
                // {
                //   // This is currently hard-coded
                //   price: 'price_1OhEDKHoJbt1xrgTfMh1VaRo',
                //   quantity: 0
                // }
              ],
              payment_behavior: 'error_if_incomplete',
              payment_settings: { save_default_payment_method: 'off' },
              expand: ['latest_invoice.payment_intent'],
            }).then(async (data: any) => {
              orgSub[0].stripeAddOnSubscription = data.id;
              orgSub[0].plan = {
                value: PlanType.Business,
                label: PlanTypeLabels[PlanType.Business],
              }
              orgSub[0].userLimit = UserLimit.Business;
              orgSub[0].mySymmioLicense = LicenseLimit.Business;
              await OrganizationSubscriptionService.save(orgSub[0]);
              paymentSuccessful(true);
            })
          }).catch((err: any) => {
            console.log(err);
          })
        }
      }
      isValidating(false);
      setIsLoading(false);
    })
    if (clientSecret) {
      confirmIntent();
    }
  }, [clientSecret])

  useEffect(() => {
    if (product && isCreating) {
      const price = Number((product?.priceInfo.unit_amount / 100).toFixed(2));
      setTotalPrice(price);
    }
  }, [product]);

  useEffect(() => {
    if (coupons.length === 0) {
      setNewPrice(null);
      setNewPriceNext(null);
    }
  }, [coupons.length]);

  const handleSubmit = (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    handleSubscriptionStart();
  }

  const handleSubscriptionStart = async () => {

    //e.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      // handleError(submitError);
      return;
    }

    isValidating(true);
    setIsLoading(true);

    if (isCreating && onInfoCreate) {
      let result = null;
      if (isNPELink && state.user) {
        let leadLink = id ? await LeadLinksService.get(id) : null;

        const organization = {
          appContent: {
            [AppContentType.PAIN]: {} as AppContent,
            [AppContentType.BEHAVIORAL_HEALTH]: {} as AppContent,
          },
          status: OrganizationStatus.Active,
          name: state.user.npeTemp!.orgName,
          industry: state.user.npeTemp!.orgIndustry,
          leadLinkId: id ? id : '',
          orgDefaultContentConfig: leadLink ? leadLink.orgDefaultContentConfig : undefined,
        };

        result = onInfoCreate(state.temp.user, organization);
      } else {
        result = onInfoCreate(state.temp.user, state.temp.organization);
      }
      if (await result !== 'SUCCESS') {
        setDiscountError(result!.toString());
        isValidating(false);
        setIsLoading(false);
        return;
      }
    }

    setIsLoading(true);
    // Retrieve customer from stripe by Email. Expand for Subscription
    let customer = await stripe2.customers.list({
      limit: 1,
      email: isCreating ? `${user.email}` : `${state.user?.email}`,
      expand: ['data.subscriptions']
    })

    // Check if a customer was found
    if (customer.data.length === 0) {
      customer = await stripe2.customers.create({
        email: isCreating ? `${user.email}` : `${state.user?.email}`,
        name: isCreating ? `${user.firstName} ${user.lastName}` : `${state.user?.firstName} ${state.user?.lastName}`,
        preferred_locales: [i18n.language]
      })
    }

    setCustomer(customer);

    await stripe2.setupIntents.create({
      customer: customer.data ? customer.data[0].id : customer.id,
      // In the latest version of the API, specifying the `automatic_payment_methods` parameter is optional because Stripe enables its functionality by default.
      automatic_payment_methods: {
        enabled: true,
      },
    }).then((intent: any) => {
      setClientSecret(intent.client_secret);
    });

    setIsLoading(false);
  }

  function calculateNextPayment(priceInfo: any): React.ReactNode {
    const today = new Date();
    const nextBillingDay = new Date(today);
    if (priceInfo) {
      if (priceInfo.interval === 'month') {
        nextBillingDay.setMonth(today.getMonth() + priceInfo.interval_count);
      } else if (priceInfo.interval === 'year') {
        nextBillingDay.setFullYear(today.getFullYear() + priceInfo.interval_count);
      }
      else {
        nextBillingDay.setFullYear(today.getFullYear() + 1);
      }

      // This part adjusts the last day of the month if it doesn't exist in the next month
      if (priceInfo.interval === 'month') {
        while (nextBillingDay.getMonth() !== (today.getMonth() + priceInfo.interval_count) % 12) {
          nextBillingDay.setDate(nextBillingDay.getDate() - 1);
        }
      }
    }
    return priceInfo ? `${nextBillingDay.toLocaleString('default', { month: 'short' })} ${nextBillingDay.getDate()}, ${nextBillingDay.getFullYear()}` : '';
  }

  const validateCouponCode = async (code: any) => {
    const validationErrorMessages: ValidationErrors = {
      couponIsValid: t('pricing.checkoutPage.discountErrors.couponIsNotValid'),
      couponNotExpired: t('pricing.checkoutPage.discountErrors.couponExpired'),
      couponValidForProduct: t('pricing.checkoutPage.discountErrors.couponNotValidForProduct'),
      couponValidForCustomer: t('pricing.checkoutPage.discountErrors.couponNotValidForCustomer'),
      couponMeetsMinimum: t('pricing.checkoutPage.discountErrors.couponNotMeetsMinimum', { price: (code.restrictions.minimum_amount / 100).toFixed(2) }),
      couponRedeemed: t('pricing.checkoutPage.discountErrors.couponNotRedeemed'),
    }
    let errors: any[] = [];
    const couponIsValid = code.active && code.coupon.valid;
    const couponNotExpired = (code.coupon.redeem_by ? code.coupon.redeem_by > Math.floor(Date.now() / 1000) : true) &&
      (code.expires_at ? code.expires_at > Math.floor(Date.now() / 1000) : true);
    const couponValidForProduct = code.coupon.applies_to ? !!code.coupon.applies_to.products.find((item: any) => item === product.id) : true;
    const couponValidForCustomer = code.customer ? customer.id === code.customer : true;
    const couponMeetsMinimum = code.restrictions.minimum_amount ? code.restrictions.minimum_amount < Number(purchasePrice ? purchasePrice : totalPrice) : true;
    const couponRedeemed = (code.coupon.max_redemptions ? code.coupon.max_redemptions > code.coupon.times_redeemed : true) &&
      (code.max_redemptions ? code.max_redemptions > code.times_redeemed : true);
    Object.entries({ couponIsValid, couponNotExpired, couponValidForProduct, couponValidForCustomer, couponMeetsMinimum, couponRedeemed }).forEach(([key, value]) => {
      if (!value)
        errors.push(validationErrorMessages[key]);
      else if (paymentCycle === PaymentCycle.Yearly && !code.coupon.name.startsWith("Y-")) {
        errors.push(t('pricing.checkoutPage.discountErrors.cannotYearly'));
      }
      else if (paymentCycle === PaymentCycle.Monthly && !code.coupon.name.startsWith("M-")) {
        errors.push(t('pricing.checkoutPage.discountErrors.cannotMonthly'));
      }
    });

    if (errors.length > 0)
      setDiscountError(errors[0]);
    return !!(errors.length === 0);
  }

  const handleApplyCode = async () => {
    setDiscountError('');
    if (coupons.length === 0) {
      if (couponValue !== '' && couponValue !== undefined) {
        const result = allCoupons.find((item: any) => item.code === couponValue.toUpperCase());
        if (result) {
          const couponValid = await validateCouponCode(result);
          if (couponValid) {
            setCoupons([result]);
            setSubscriptionCoupon(result.coupon.id);
            setCouponValue('');
            let newPrice = 0;
            if (result.coupon.amount_off) {
              newPrice = Number(purchasePrice ? purchasePrice : totalPrice) - (result.coupon.amount_off / 100);
            } else {
              newPrice = Number(purchasePrice ? purchasePrice : totalPrice) - ((Number(purchasePrice ? purchasePrice : totalPrice) * result.coupon.percent_off) / 100);
            }
            setNewPrice(newPrice);
            if (result.coupon.duration_in_months) {
              if (paymentCycle === PaymentCycle.Yearly && result.coupon.duration_in_months > 12)
                setNewPriceNext(newPrice);
              else if (paymentCycle === PaymentCycle.Monthly && result.coupon.duration_in_months > 1)
                setNewPriceNext(newPrice);
            }
          }
        } else {
          setDiscountError(t('pricing.checkoutPage.discountErrors.enterValid'));
        }
      } else {
        setDiscountError(t('pricing.checkoutPage.discountErrors.enterValid'));
      }
    } else {
      setDiscountError(t('pricing.checkoutPage.discountErrors.only1'));
    }
  }

  useEffect(() => {
    const retrieveAllCoupons = async () => {
      const coupons = await stripe2.promotionCodes.list({ expand: ['data.coupon.applies_to'] });
      setAllCoupons(coupons.data);

      if (couponId) {
        await stripe2.coupons.retrieve(couponId).then((result: any) => {
          if (result) {
            if (paymentCycle === PaymentCycle.Monthly && result.duration_in_months >= 1) {
              setSubscriptionCoupon(couponId);
            }
            else if (paymentCycle === PaymentCycle.Yearly && result.duration_in_months === null) {
              setSubscriptionCoupon(couponId);
            }
          }
        }).catch((e: any) => {
          console.log(e);
          setCouponExists(false);
        });

      }
      else {
        setCouponExists(false);
      }

      let customer = await stripe2.customers.list({
        limit: 1,
        email: isCreating && state.temp?.user?.email ? `${state.temp.user.email}` : `${state.user?.email}`,
        expand: ['data.subscriptions']
      });
      setCustomer(customer);
    }
    retrieveAllCoupons();
  }, [paymentCycle]);

  useEffect(() => {
    const price = purchasePrice ? purchasePrice : totalPrice;
    const changeNewPrice = async () => {
      let newPrice = 0;
      const promos = await stripe2.coupons.list();
      const result = promos.data.find((item: any) => item.id === couponId);
      if (result) {
        if ((paymentCycle === PaymentCycle.Yearly && result.duration_in_months === null) || (
          paymentCycle === PaymentCycle.Monthly && result.duration_in_months >= 1)) {
          if (result.amount_off) {
            newPrice = Number(price) - (result.amount_off / 100);
          } else {
            newPrice = Number(price) - ((Number(price) * result.percent_off) / 100);
          }
          setNewPrice(newPrice > 0 ? newPrice : 0);
          if (result.duration !== 'once')
            setNewPriceNext(newPrice > 0 ? newPrice : 0);
        }
      }
      else {
        setCouponExists(false);
      }

    }
    if (price && couponId) {
      changeNewPrice();
    }
  }, [purchasePrice, totalPrice]);

  return (
    <div className={`${COMPONENT_CLASS}`}>
      <Loader isVisible={isLoading} />
      {paymentFailedBanner && (
        <div style={{ width: '90%', marginLeft: 'auto', marginRight: 'auto' }}>
          <WarningItem
            iconColor="#E7514F"
            onClose={() => { setPaymentFailedBanner(false); }}
            backgroundColor="#FFE9E5"
            title={t('pricing.checkoutPage.paymentDeclined.banner.title')}
            text={t('pricing.checkoutPage.paymentDeclined.banner.subText')}
          />
        </div>
      )}

      <div className={`${!isCreating ? `${COMPONENT_CLASS}__container-modal` : `${COMPONENT_CLASS}__container`}`}>
        <div className={`${COMPONENT_CLASS}__summary`}>
          <div className={`${COMPONENT_CLASS}_summary_line`}>
            {
              isCreating && (
                <div style={{ fontSize: '20px' }}>{product?.name ?? ''}</div>
              )
            }
            {
              isUpgrading && product && (
                <div style={{ fontSize: '20px' }}>{product.data.name}</div>
              )
            }
            <div style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={onChangePlan}>{t('pricing.checkoutPage.changePlan')}</div>
          </div>
          {isUpgrading && (
            <div style={{ flexDirection: 'column', alignItems: 'start' }} className={`${COMPONENT_CLASS}_summary_line`}>
              <div style={{ fontWeight: 'bold' }}>{t('pricing.checkoutPage.accChange')}</div>
              <ul style={{ marginLeft: '1rem', marginTop: '1rem', listStyleType: 'disc' }}>
                <li>{t('pricing.checkoutPage.userLimit', { starterLimit: UserLimit.Starter, businessLimit: UserLimit.Business })}</li>
                <li>{t('pricing.checkoutPage.licenseLimit', { starterLimit: LicenseLimit.Starter, businessLimit: LicenseLimit.Business })}</li>
                <li>
                  {paymentCycle === PaymentCycle.Yearly
                    ?
                    t('pricing.checkoutPage.year', { price: priceToShow, date: moment(new Date()).format('MMMM D Y') })
                    :
                    t('pricing.checkoutPage.month', { price: priceToShow, date: moment(new Date()).format('MMMM D Y') })}
                </li>
              </ul>
            </div>
          )}
          <hr className={`${COMPONENT_CLASS}_divider ${!isCreating ? `${COMPONENT_CLASS}__upgrade-divider` : ""}`} />
          <div className={`${COMPONENT_CLASS}_summary_line`}>
            <div style={{ fontWeight: 'bold' }}>{t('pricing.checkoutPage.todayPay')}</div>
            {(newPrice || newPrice === 0) && (
              <div style={{ display: 'flex' }}>
                <div style={{ textDecoration: 'line-through', marginRight: '0.5rem', color: 'gray' }}>${purchasePrice ? purchasePrice?.toFixed(2) : totalPrice?.toFixed(2) ?? '...'}</div>
                <div>${newPrice.toFixed(2) ?? '...'}</div>
              </div>
            )}
            {(!newPrice && newPrice !== 0) && (<div>${purchasePrice ? purchasePrice.toFixed(2) : totalPrice?.toFixed(2) ?? '...'}</div>)}
          </div>
          {isCreating && (
            <>
              <hr className={`${COMPONENT_CLASS}_divider ${!isCreating ? `${COMPONENT_CLASS}__upgrade-divider` : ""}`} />
              <div className={`${COMPONENT_CLASS}_summary_line`}>
                <div style={{ fontWeight: 'bold' }}>{t('pricing.checkoutPage.nextPaymentDate', { date: product && product.priceInfo ? calculateNextPayment(product?.priceInfo) : calculateNextPayment(products && products.find((item: any) => item.product.id === PlanId.Business).prices ? products.find((item: any) => item.product.id === PlanId.Business).prices.find((item: any) => paymentCycle === PaymentCycle.Monthly ? item.data.interval === "month" : item.data.interval === null).data : null) })}</div>
                <div>${newPriceNext ? newPriceNext.toFixed(2) : purchasePrice ? purchasePrice.toFixed(2) : totalPrice?.toFixed(2) ?? '...'}</div>
              </div>
            </>
          )}
          {
            !couponExists && (
              <><form style={!isCreating ? { display: 'flex', justifyContent: 'space-between', gap: '1rem', padding: '0rem 1.25rem 1.25rem 1.25rem' }
                : { display: 'flex', justifyContent: 'space-between', alignItems: 'baseline', flexFlow: 'wrap', paddingLeft: '1.25rem', paddingRight: '1.25rem', paddingBottom: '1.25rem' }}>            <div style={{ fontWeight: '300' }}>
                  <TextTypeInput
                    id="discountCode"
                    type="text"
                    label={t('pricing.checkoutPage.discount')}
                    inputClassName={`${!isCreating && `${COMPONENT_CLASS}__discount-field`} ${(discountError) && `${FORM_COMPONENT_CLASS} -showerror`}`}
                    hideLabel={true}
                    style={isCreating ? { width: 'auto' } : { width: '100%' }}
                    registerHook={register}
                    registerOptions={{
                      value: couponValue,
                      onChange: (e) => { setCouponValue(e.target.value); },
                      onBlur: (e) => { if (discountError && e.target.value === '') { setDiscountError(''); } },
                    }} />
                </div>
                {(discountError) && (
                  <div role="alert" className={`${FORM_COMPONENT_CLASS} -coupon-error`}>
                    {discountError}
                  </div>
                )}
                <div>
                  <Button type="default" disabled={isLoading} buttonType="button" onClick={handleApplyCode} buttonText="Apply" />
                </div>
              </form><div style={{ marginLeft: '1.25rem', marginRight: '1.25rem' }}>
                  {coupons.map((coupon, i) => (<TagComponent
                    id={i.toString()}
                    onCloseClick={(index) => {
                      const newCoupons = [...coupons];
                      newCoupons.splice(parseInt(index), 1);
                      setCoupons(newCoupons);
                    }}
                    key={`coupon-${i}`}
                  >{coupon.code}</TagComponent>))}
                </div></>
            )
          }
          {isCreating && (
            <>
              <hr className={`${COMPONENT_CLASS}_divider ${!isCreating ? `${COMPONENT_CLASS}__upgrade-divider` : ""}`} />
              <div className={`${COMPONENT_CLASS}_summary_line`}>
                <div className={`${COMPONENT_CLASS}__summary-disclaimer`}>{t('pricing.checkoutPage.renewalInfo', { date: calculateNextPayment(product?.priceInfo) })}</div>
              </div>
            </>
          )}
        </div>
        <form onSubmit={handleSubmit} className={`${COMPONENT_CLASS}__form`} id="checkout-form">
          <PaymentElement />
        </form>
      </div>

      <div className={`${!isCreating ? `${COMPONENT_CLASS}__bottom-text-modal` : `${COMPONENT_CLASS}__bottom-text`}`} dangerouslySetInnerHTML={{ __html: t('pricing.checkoutPage.termsAndConditions') }}>
      </div>
      {!isCreating && setUpgradeModalOpen && (
        <div className={`${COMPONENT_CLASS}__start-subscription`}>
          <Button
            type="link"
            inputClassName={`${COMPONENT_CLASS}__actions__cancel`}
            buttonText={t('pricing.checkoutPage.keepCurrent')}
            onClick={() => {
              setUpgradeModalOpen(false);
            }} />
          <Button
            buttonType="submit"
            form="checkout-form"
            inputClassName="action-button"
            buttonText={t('pricing.checkoutPage.payAndUpgrade')} />

        </div>)}
    </div>
  );
}

export default CheckoutForm;