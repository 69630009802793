/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { MskScore } from "../../models/interfaces/msk-score";
import { User } from "../../models/interfaces/user";
import { useEffect, useState } from "react";
import { Loader } from "../loader/loader";
import MskScoreService from "../../utilities/services/msk-score-service";
import { SymmioAccessType } from "../../models/enumerations/symmio-access-type";
import AssessmentResponse from "../../models/interfaces/assessment-response.entity";
import DonutChart from "../charts/donut-chart";
import { Button } from "../button/button";
import RiskChartVertical from "../charts/risk-chart-vertical";
import RiskFactorUtil from "../../utilities/risk-factor-util";
import { Utils } from "../../utilities/utils";
import FocusAreaCard from "../focus-areas-card/focus-areas-card";
import GrayFooterCard from "../gray-footer-card/gray-footer-card";
import MskScoreUtil from "../../utilities/msk-score-util";
import { ReactComponent as CheckmarkIcon } from "../../assets/icons/nav/icon_checkmark.svg";
import { ReactComponent as EmptyCircleIcon } from "../../assets/icons/outline/icon_empty-circle.svg";
import { ReactComponent as LineIcon } from "../../assets/icons/outline/icon_line.svg";
import { ReactComponent as PainLocationsIcon } from "../../assets/icons/solid/broken-heart-icon.svg";
import Badge from "../badge/badge";
import NonLinearLineChart from "../charts/non-linear-line-chart";
import LineChartJS from "../charts/line-chart-js";
import WellnessProgramService from "../../utilities/services/wellness-program-service";
import { WellnessProgram } from "../../models/interfaces/wellness-program";
import { Modal } from "../modal/modal";
import UserUtil from "../../utilities/user-util";
import { enqueueSnackbar } from "notistack";
import FunctionService from "../../utilities/services/function-service";
import OrganizationService from "../../utilities/services/organization-service";
import { useTranslation } from "react-i18next";
import UserActivityService from "../../utilities/services/user-activity-service";
import { DateTime } from "luxon";
import { ActivityType } from "../../models/enumerations/activity-type";
import { useAuthState } from "../../utilities/contexts/auth-state-context";

const COMPONENT_CLASS = "c-user-profile-summary";

// -----------------------------------------------------------------------------------------
// #region Intefaces
// -----------------------------------------------------------------------------------------

interface UserProfileSummaryProps {
    mskScore?: MskScore;
    allMskScores: MskScore[];
    setAllMskScores: Function;
    user: User;
    assessmentResponses: AssessmentResponse[];
    openLicenseModal: Function;
    openRemoveLicense: Function;
    hasAvailableLicenses: boolean;
    canAssignLicense: boolean;
}

// #endregion Intefaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const UserProfileSummary: React.FC<UserProfileSummaryProps> = (props: UserProfileSummaryProps) => {
    const { user, mskScore, assessmentResponses, allMskScores, setAllMskScores } = props;
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [baseLineAssessment, setBaseLineAssessment] = useState<string>();
    const [numOfPain, setNumOfPain] = useState<number>(0);
    // const [, setFocusAreas] = useState<string>()
    const [wellnessScoreHistory, setWellnessScoreHistory] = useState<{ x: string, y: number }[]>();
    const [healthRiskHistory, setHealthRiskHistory] = useState<{ x: string, y: number }[]>();
    const [wellnessProgram, setWellnessProgram] = useState<WellnessProgram>();
    const [openRetestModal, setOpenRetestModal] = useState(false);
    const { state } = useAuthState();

    useEffect(() => {
        const getAllMskScores = async () => {
            if (!allMskScores) {
                const mskScores = await MskScoreService.getBy([{
                    field: "userId",
                    operator: "==",
                    value: user.id
                }], [{
                    field: "created",
                    direction: "asc"
                }])

                setAllMskScores(mskScores);
            }
        }
        getAllMskScores();
    }, [])
    useEffect(() => {
        const getBaseLine = async () => {
            if (mskScore && mskScore.percentage) {
                // Get Baseline assessment
                const baseLine = await MskScoreService.getBy([{
                    field: "userId",
                    operator: "==",
                    value: user.id
                }], [{
                    field: "created",
                    direction: "asc"
                }], 1)
                setBaseLineAssessment(baseLine && baseLine.length === 1 ? moment(baseLine[0].created?.seconds ? baseLine[0].created.seconds * 1000 : NaN).format("MMM DD, YYYY") : "")
            }
        }
        const getNumOfPain = () => {
            if (mskScore || assessmentResponses) {
                setNumOfPain(MskScoreUtil.getNumberOfPainLocations(mskScore, assessmentResponses));
            }
        }
        const getGraphData = async () => {
            if (allMskScores) {
                let tempWellnessHistory = []
                let tempHealthRiskHistory = []

                for (const mskScore of allMskScores) {
                    if (!mskScore.percentage) {
                        continue;
                    }
                    tempWellnessHistory.push({
                        x: moment(mskScore && mskScore.created?.seconds ? mskScore.created.seconds * 1000 : NaN).format("MMM DD, YY"),
                        y: mskScore.percentage
                    })
                    tempHealthRiskHistory.push({
                        x: moment(mskScore && mskScore.created?.seconds ? mskScore.created.seconds * 1000 : NaN).format("MMM DD, YY"),
                        y: mskScore.riskFactor?.percentage ?? 0
                    })
                }
                setWellnessScoreHistory(tempWellnessHistory);
                setHealthRiskHistory(tempHealthRiskHistory)
            }
        }
        const initialLoad = async () => {
            Promise.all([
                getBaseLine(),
                getNumOfPain(),
                getGraphData(),
            ]).then(() => setIsLoading(false))
        }
        initialLoad();

    }, [mskScore, allMskScores, assessmentResponses])

    useEffect(() => {
        const getWellnessProgram = async () => {
            if (user.wellnessProgramId) {
                let fetchedWellnessProgram = await WellnessProgramService.get(user.wellnessProgramId);

                if (fetchedWellnessProgram) {
                    setWellnessProgram(fetchedWellnessProgram);
                }
            }
        }

        if (!wellnessProgram) {
            getWellnessProgram();
        }
    }, [user.wellnessProgramId])

    return (
        <div className={`${COMPONENT_CLASS}`}>
            <Loader isVisible={isLoading} />

            <Modal
                isOpen={openRetestModal}
                isLoading={false}
                onClose={setOpenRetestModal}
                defaultModalActions={true}
                onSubmit={() => {
                    if (wellnessProgram && user.id && mskScore) {
                        UserUtil.handleRetestClick(user, wellnessProgram, mskScore, true, state.user ?? undefined).then(() => {
                            setWellnessProgram(undefined);

                            if (user.organizationName) {
                                FunctionService.sendRetestRequestedEmail(user, user.organizationName);
                            }
                            else if (user.organizationId) {
                                OrganizationService.get(user.organizationId).then((org) => {
                                    if (org && org.name) {
                                        FunctionService.sendRetestRequestedEmail(user, org.name);
                                    }
                                });
                            }
                            UserActivityService.add({
                                date: DateTime.now().toISODate(),
                                type: ActivityType.AdministeredRetake,
                                userId: user.id ?? ""
                            }, state.user)
                            enqueueSnackbar(t('userProfile.summary.successRetest'), { variant: "toast", width: "450px" });
                            setOpenRetestModal(false);
                        }).catch(() => {
                            setOpenRetestModal(false);
                            // Logging of this error will be in our DB.
                            enqueueSnackbar(t('userProfile.summary.failedRetest'), { variant: "error" });
                        })
                    }
                    else {
                        enqueueSnackbar(t('userProfile.summary.cantRetest'), { variant: "info" });
                    }
                }}
                onCancel={() => setOpenRetestModal(false)}
                title={t('userProfile.summary.retestTitle')}
                submitButtonText={t('general.yes')}
                cancelButtonText={t('buttons.btn_cancel')}
            >
                <div className="mb-6">
                    <p className="text-sm font-light mb-4" dangerouslySetInnerHTML={{ __html: t('userProfile.summary.retestText') }}></p>
                </div>
            </Modal>

            <div className="flex flex-wrap gap-5">
                <div className={`${COMPONENT_CLASS}__onboarding-section-card`}>
                    <h3 className="mb-5">
                        {t('userProfile.summary.onboarding')}
                    </h3>
                    <div className="flex gap-2">
                        <div>
                            <div className={`${COMPONENT_CLASS}__icon-container-20`}>
                                {user.isAuthenticated
                                    ? <CheckmarkIcon className="checkmark-icon" />
                                    : <EmptyCircleIcon />
                                }
                            </div>
                            <div className="h-12 flex items-center justify-center" style={{ padding: "2px" }}>
                                <LineIcon color={`${user.isAuthenticated ? "#00DFBC" : "#D0D0D3"}`} className="max-h-full" />
                            </div>
                        </div>

                        <div className="flex flex-col">
                            <span className="text-xs font-medium">
                                {t('userProfile.summary.accountCreated')}
                            </span>
                            <span className="text-xs font-light">
                                {moment(user.created?.seconds ? user.created.seconds * 1000 : NaN).format("MMM DD, YYYY")}
                            </span>
                        </div>

                        <div className="ml-auto">
                            {user.isAuthenticated
                                ? <Badge text={t('userProfile.summary.done')} />
                                : <Badge text={t('userProfile.summary.pending')} bgColor="#D0D0D3" textColor="#121622" />}
                        </div>
                    </div>

                    <div className="flex gap-2">
                        <div>
                            <div className={`${COMPONENT_CLASS}__icon-container-20`}>
                                {baseLineAssessment
                                    ? <CheckmarkIcon className="checkmark-icon" />
                                    : <EmptyCircleIcon />
                                }
                            </div>
                            <div className="h-12 flex items-center justify-center" style={{ padding: "2px" }}>
                                <LineIcon color={`${baseLineAssessment ? "#00DFBC" : "#D0D0D3"}`} className="max-h-full" />
                            </div>
                        </div>

                        <div className="flex flex-col">
                            <span className="text-xs font-medium">
                                {t('userProfile.summary.baseline')}
                            </span>
                            <span className="text-xs font-light">
                                {baseLineAssessment}
                            </span>
                        </div>

                        <div className="ml-auto">
                            {baseLineAssessment
                                ? <Badge text={t('userProfile.summary.done')} />
                                : <Badge text={t('userProfile.summary.pending')} bgColor="#D0D0D3" textColor="#121622" />}
                        </div>
                    </div>

                    <div className="flex gap-2">
                        <div>
                            <div className={`${COMPONENT_CLASS}__icon-container-20`}>
                                {user.symmioAccess === SymmioAccessType.AppLicense
                                    ? <CheckmarkIcon className="checkmark-icon" />
                                    : <EmptyCircleIcon />
                                }
                            </div>
                        </div>

                        <div className="flex flex-col gap-1">
                            <span className="text-xs font-medium">
                                {t('general.wellnessProgram')}
                            </span>
                            {user.symmioAccess !== SymmioAccessType.AppLicense && props.hasAvailableLicenses && props.canAssignLicense
                                ?
                                <span className="text-xs font-medium underline cursor-pointer w-fit" onClick={() => props.openLicenseModal()}>
                                    {t('userProfile.summary.enroll')}
                                </span>
                                : user.symmioAccess === SymmioAccessType.AppLicense && props.canAssignLicense &&
                                <span className="text-xs font-medium underline cursor-pointer w-fit" onClick={() => props.openRemoveLicense()}>
                                    {t('userProfile.summary.remove')}
                                </span>
                            }
                        </div>

                        <div className="ml-auto">
                            {user.symmioAccess === SymmioAccessType.AppLicense
                                ? <Badge text={t('userProfile.summary.done')} />
                                : <Badge text={t('userProfile.summary.noAccess')} bgColor="#D0D0D3" textColor="#121622" />
                            }
                        </div>
                    </div>
                </div>

                <div className={`${COMPONENT_CLASS}__score-section-card items-center`}>
                    <h3 className="mb-4">
                        {t('userProfile.summary.overallScore')}
                    </h3>
                    <div className={`${COMPONENT_CLASS}__donut-chart`}>
                        <DonutChart
                            percentage={mskScore?.percentage}
                            title={t('userProfile.wellnessScore')}
                        />
                    </div>
                    {mskScore && wellnessProgram?.isCurrent &&
                        <div className="mt-4">
                            <Button
                                buttonStyle="secondary"
                                buttonText={t('userProfile.summary.retest')}
                                onClick={() => setOpenRetestModal(true)} />
                        </div>
                    }
                </div>

                <div className={`${COMPONENT_CLASS}__section-container`}>
                    <div className={`${COMPONENT_CLASS}__score-section-card items-center`}>
                        <div className="flex flex-col" style={{ maxWidth: "263px" }}>
                            <h3 className="flex gap-3 items-center justify-center mb-4">
                                {t('userProfile.summary.mskRisk')}
                            </h3>
                            <div className="w-full h-44">
                                <RiskChartVertical
                                    heightPercentage={RiskFactorUtil.convertRiskFactorToDisplayPercent(mskScore?.riskFactor?.percentage)}
                                    score={mskScore?.riskFactor?.percentage}
                                    breakpoints={[
                                        { min: 73, max: 100, label: t('userProfile.summary.highRisk'), bgColor: "#FFA998" },
                                        { min: 28, max: 72, label: t('userProfile.summary.moderateRisk'), bgColor: "#FFC79C" },
                                        { min: 1, max: 27, label: t('userProfile.summary.slightRisk'), bgColor: "#ADDFFD" },
                                        { min: 0, max: 0, label: t('userProfile.summary.lowRisk'), bgColor: "#8BE3CE" },
                                    ]}
                                    decimals={false}
                                />
                            </div>
                        </div>
                    </div>

                    <div className={`${COMPONENT_CLASS}__score-section-card items-center justify-center`}>
                        <div className="w-fit flex gap-1 items-center justify-center">
                            <div>
                                <PainLocationsIcon />
                            </div>
                            <div className="font-bold text-2xl">
                                {numOfPain}
                            </div>
                            <div className="uppercase font-bold text-10" dangerouslySetInnerHTML={{ __html: t('userProfile.summary.painLocations') }}></div>
                        </div>
                    </div>
                </div>

                <div className={`${COMPONENT_CLASS}__section-container`}>
                    {mskScore && mskScore.focusAreas && mskScore.focusAreas.length > 0 &&
                        <FocusAreaCard focusAreas={mskScore.focusAreas} title={t('userProfile.summary.areasOfFocus')} />
                    }

                    <GrayFooterCard mainText={Utils.formatDate(mskScore?.created ? mskScore.created.toDate() : null)} subText={t('userProfile.summary.lastAssessment')} footerText={mskScore && mskScore.created ? Utils.getHowManyDaysAgo(mskScore.created.toDate()) : ""} />
                </div>
            </div>
            {wellnessScoreHistory && (
                <div className={`${COMPONENT_CLASS}__score-section-card justify-center my-5`} style={{ width: "100%", maxWidth: "100%", minWidth: "100%" }}>
                    <LineChartJS
                        data={wellnessScoreHistory.map(history => history.y)}
                        title={t('userProfile.summary.scoreHistory')}
                        labels={wellnessScoreHistory.map(history => history.x)}
                        height={"200px"}
                        horizontalScrolling={true}
                        pointRadius={7}
                        pointBorderWidth={3}
                        borderWidth={5}
                        pointFontSize={16}
                        fontFamily={'pragmatica-extended'}
                        pointLabelOffset={0}
                        xLabelFontSize={14}
                        paddingTop={30}
                        paddingBottom={20}
                        maintainAspectRatio={false}
                    />
                </div>
            )}

            {healthRiskHistory && (
                <div className={`${COMPONENT_CLASS}__score-section-card justify-center`} style={{ width: "100%", maxWidth: "100%", minWidth: "100%" }}>
                    <NonLinearLineChart
                        dataPoints={healthRiskHistory.map(history => {
                            if (history.y === 0) {
                                return 0.5
                            }
                            else {
                                return history.y
                            }
                        })}
                        labels={healthRiskHistory.map(history => history.x)}
                        title={t('userProfile.summary.mskRiskHistory')}
                        sectionDetails={{
                            sections: 4,
                            rangeOne: { start: 0, end: 0.9 },
                            rangeTwo: { start: 1, end: 29 },
                            rangeThree: { start: 30, end: 74 },
                            rangeFour: { start: 75, end: 100 },
                            backgroundColorOrder: { first: "#8BE3CE", second: "#ADDFFD", third: "#FFE9CC", fourth: "#FFA998" },
                            dataPointColorOrder: { first: "#00DFBC", second: "#43B5F8", third: "#FFC79C", fourth: "#E7514F" },
                            yAxisLabels: { first: t('userProfile.summary.low'), second: t('userProfile.summary.slight'), third: t('userProfile.summary.moderate'), fourth: t('userProfile.summary.high') },
                            decimals: false
                        }}
                        toolTipLabel={true}
                        toolTipLabelAbstracted={true}
                    />
                </div>
            )}

        </div>
    );
}

export default UserProfileSummary;
