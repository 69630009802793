/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { User } from '../../models/interfaces/user';
import { useHistory, useParams } from 'react-router-dom';
import { useAuthState } from '../../utilities/contexts/auth-state-context';
import BaseCardLayout from '../../components/base-card-layout/base-card-layout';
import BaseCardLayoutActions from '../../components/base-card-layout/base-card-layout-actions';
import LinkAccountSetupScreen from '../../components/link-account-setup-screen/link-account-setup-screen';
import { DateTime } from 'luxon';
import { LeadLink } from '../../models/interfaces/lead-link';
import { DeeplinkStatus } from '../../models/enumerations/deeplink-status';
import LeadLinksService from '../../utilities/services/lead-links-service';
import errorMessageLogo from "../../assets/images/symmio-logos/symmio-logo-horizontal-dark.png";
import FunctionService from '../../utilities/services/function-service';
import { collection, doc, getFirestore } from 'firebase/firestore';
import { TextTypeInput } from '../../components/forms';
import { SubscriptionLink } from '../../models/interfaces/subscription-link';
import SubscriptionLinkService from '../../utilities/services/subscription-link-service';
import { Button } from '../../components/button/button';
import { useTranslation } from 'react-i18next';
import { LanguageSelectorUserMenu } from '../../components/language-selector-user-menu/language-selector-user-menu';
import { IndustryEnglishLabel, IndustryListOptions, IndustryValue } from '../../models/industry';

const COMPONENT_CLASS = "p-link-account-creation";
const LEAD_CAPTURE_LINK_TYPE = "leadCapture";
const SUBSCRIPTION_LINK_TYPE = "subscriptionLink";

interface LinkAccountCreationParams {
    id: string;
    type?: string;
}

const LinkAccountCreation: React.FC = () => {
    const db = getFirestore();
    const { id, type } = useParams<LinkAccountCreationParams>();
    const { t } = useTranslation();
    const isLeadCaptureLink = type === LEAD_CAPTURE_LINK_TYPE;
    const isSubscriptionLink = type === SUBSCRIPTION_LINK_TYPE;
    const params = new URLSearchParams(window.location.search);
    const firstName = params.get("first_name") || '';
    const lastName = params.get("last_name") || '';

    let industryParam: string | null | undefined;
    let industry: string | undefined;
    let otherIndustry: string | undefined;
    if (isLeadCaptureLink) {
        industryParam = params.get("industry");

        industry = industryParam
            ? IndustryListOptions.find((x) => x.value === industryParam)
                ? industryParam : IndustryValue.Other
            : undefined;

        if (industry === IndustryValue.Other && industryParam && industryParam !== IndustryValue.Other) {
            otherIndustry = industryParam;
        }
    }

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm({
        defaultValues: {
            firstName: firstName ?? '',
            lastName: lastName ?? '',
            industry: industry,
            otherIndustry: otherIndustry,
        }
    });
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { state, setState } = useAuthState();
    const [isLoading, setIsLoading] = useState(false);
    const [isCompleted, setIsCompleted] = useState(false);
    let history = useHistory();
    const [user, setUser] = useState<User>();
    const [linkErrorMessageHeader, setLinkErrorMessageHeader] = useState(t('pages.landingPage.errors.default_head'));
    const [linkErrorMessageBody, setLinkErrorMessageBody] = useState(t('pages.landingPage.errors.default_body'));
    const [showErrorModal, setShowErrorModal] = useState(false);
    const selectedOption = watch("industry");

    useEffect(() => {
        if (state.authenticated) {
            history.replace("/");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const getLeadCaptureLink = async () => {
            let fetchedLink: LeadLink | null = null;

            try {
                fetchedLink = await LeadLinksService.get(id);
                if (fetchedLink) {
                    fetchedLink.clicksTotalCount !== undefined ? (fetchedLink.clicksTotalCount += 1) : (fetchedLink.clicksTotalCount = 1);

                    const currentYear = (new Date()).getUTCFullYear();
                    const currentMonthIndex = (new Date()).getUTCMonth();

                    if (fetchedLink.performance && fetchedLink.performance[currentYear] && fetchedLink.performance[currentYear][currentMonthIndex]) {
                        fetchedLink.performance[currentYear][currentMonthIndex].clicksCount += 1;
                    }
                    else {
                        fetchedLink.performance = {
                            ...fetchedLink.performance,
                            [currentYear]: {
                                [currentMonthIndex]: {
                                    clicksCount: 1,
                                    leadsCount: 0,
                                    starterCount: 0,
                                    businessCount: 0,
                                    enterpriseCount: 0,
                                }
                            }
                        }
                    }

                    await FunctionService.updateLeadLink(fetchedLink);
                }
            } catch (err) {
                console.error("Cannot fetch the lead capture link ID", err);
                setShowErrorModal(true);
            } finally {
                await checkLeadLinkValidity(fetchedLink);
            }
        }

        const getSubscriptionLink = async () => {
            let fetchedLink: SubscriptionLink | null = null;

            try {
                fetchedLink = await SubscriptionLinkService.get(id);
                if (fetchedLink) {
                    await FunctionService.updateLeadLink(fetchedLink);
                }
            } catch (err) {
                console.error("Cannot fetch the subscription link ID", err);
                setShowErrorModal(true);
            } finally {
                await checkLeadLinkValidity(fetchedLink);
            }
        }

        if (isLeadCaptureLink) {
            getLeadCaptureLink();
        }
        else if (isSubscriptionLink) {
            getSubscriptionLink();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, isLeadCaptureLink, isSubscriptionLink])

    const checkLeadLinkValidity = async (link: LeadLink | SubscriptionLink | null) => {
        const nowUtc = DateTime.now().toUTC();

        if (!link) {
            setShowErrorModal(true);
            return;
        }

        const startDate = link.startDate ? DateTime.fromISO(link.startDate) : null;
        const endDate = link.endDate ? DateTime.fromISO(link.endDate) : null;

        const errorFutureHeader = t('pages.landingPage.errors.future_head');
        const errorFutureBody = t('pages.landingPage.errors.future_body', { startDate: startDate?.toLocaleString(DateTime.DATE_FULL) });

        const errorExpiredHeader = t('pages.landingPage.errors.expired_head');
        const errorExpiredBody = t('pages.landingPage.errors.expired_body');

        const errorInactiveHeader = t('pages.landingPage.errors.inactive_head');
        const errorInactiveBody = t('pages.landingPage.errors.inactive_body');

        // If start date is a future date
        if (startDate && nowUtc <= startDate.toUTC().startOf("day")) {
            setLinkErrorMessageHeader(errorFutureHeader);
            setLinkErrorMessageBody(errorFutureBody);
            setShowErrorModal(true);
            return;
        }

        // If end date has passed
        else if (endDate && nowUtc >= endDate.toUTC().endOf("day")) {
            setLinkErrorMessageHeader(errorExpiredHeader);
            setLinkErrorMessageBody(errorExpiredBody);
            setShowErrorModal(true);
            return;
        }

        // If the lead capture link is not active
        else if (link.status !== DeeplinkStatus.Active) {
            setLinkErrorMessageHeader(errorInactiveHeader);
            setLinkErrorMessageBody(errorInactiveBody);
            setShowErrorModal(true);
            return;
        }
    }

    const onSubmit = async (data: any) => {
        setIsLoading(true);
        let { firstName, lastName, industry, otherIndustry } = data;

        // Capitalize first letter
        firstName = firstName.charAt(0).toUpperCase() + firstName.slice(1)
        lastName = lastName.charAt(0).toUpperCase() + lastName.slice(1)

        let user = {
            id: doc(collection(db, 'users')).id,
            firstName: firstName,
            lastName: lastName,
            industry: industry === IndustryValue.Other ? otherIndustry : IndustryEnglishLabel[industry],
        } as User;

        setUser(user);

        setIsCompleted(true);
    }

    const errorMessage = (
        <BaseCardLayout>
            <div className="p-landing__error">
                <div className="p-landing__error-content-container">
                    <div className="p-landing__error-image-container">
                        <img src={errorMessageLogo} alt="Symmio Logo" />
                    </div>

                    <div className="p-landing__error-message-container">
                        <h1>{linkErrorMessageHeader}</h1>
                        <p className="whitespace-pre-line" dangerouslySetInnerHTML={{ __html: linkErrorMessageBody }}></p>
                    </div>
                </div>
            </div>
        </BaseCardLayout>
    );

    return (
        <>
            <div className="user-menu">
                <LanguageSelectorUserMenu userPortal={false} />
            </div>
            {
                // if
                isCompleted && (
                    <LinkAccountSetupScreen user={user!} />
                )
            }
            {
                // if
                !isCompleted && (
                    showErrorModal
                        ?
                        errorMessage
                        :
                        <BaseCardLayout>
                            <div className="flex justify-center" style={{ marginTop: "2.3%", marginBottom: "14rem" }}>
                                <div className={`${COMPONENT_CLASS}`}>

                                    <h4 className="-light">{t('buttons.btn_getStarter')}</h4>
                                    <p>{t('pages.linkAccountCreation.welcome')}</p>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className={`${COMPONENT_CLASS}__content`}>
                                            <div className={`${COMPONENT_CLASS}__field`}>
                                                {errors.firstName && (
                                                    <div
                                                        role="alert"
                                                        className={`${COMPONENT_CLASS} -error`}>
                                                        {t('manageAccount.firstName_req')}
                                                    </div>
                                                )}
                                                <TextTypeInput
                                                    id='firstName'
                                                    label={t('manageAccount.firstName')}
                                                    hideLabel={true}
                                                    type='text'
                                                    readonly={!!firstName}
                                                    inputClassName={`c-input__input -displaytext ${errors.firstName &&
                                                        `${COMPONENT_CLASS} -showerror`
                                                        }`}
                                                    registerHook={register}
                                                    registerOptions={{
                                                        required: true
                                                    }}
                                                    errorState={errors.firstName} />
                                            </div>
                                            <div className={`${COMPONENT_CLASS}__field`}>
                                                {errors.lastName && (
                                                    <div
                                                        role="alert"
                                                        className={`${COMPONENT_CLASS} -error`}>
                                                        {t('manageAccount.lastName_req')}
                                                    </div>
                                                )}
                                                <TextTypeInput
                                                    id='lastName'
                                                    label={t('manageAccount.lastName')}
                                                    hideLabel={true}
                                                    type='text'
                                                    readonly={!!lastName}
                                                    inputClassName={`c-input__input -displaytext ${errors.lastName &&
                                                        `${COMPONENT_CLASS} -showerror`
                                                        }`}
                                                    registerHook={register}
                                                    registerOptions={{
                                                        required: true
                                                    }}
                                                    errorState={errors.lastName} />
                                            </div>
                                            {isLeadCaptureLink && industryParam &&
                                                <>
                                                    <div className={`${COMPONENT_CLASS}__field`}>
                                                        {errors.industry && (
                                                            <div role="alert" className={`${COMPONENT_CLASS} -error`}>
                                                                {t('pricing.organizationPage.selectIndustry')}
                                                            </div>
                                                        )}
                                                        <select id="industry"
                                                            className={`c-input__input ${errors.industry && `${COMPONENT_CLASS} -showerror`}`}
                                                            {...register("industry", {
                                                                required: true,
                                                                value: industry
                                                            })}
                                                            style={{ display: 'flex' }}
                                                        >
                                                            {IndustryListOptions.map((option) =>
                                                                <option value={option.value}>{option.label}</option>
                                                            )}
                                                        </select>
                                                    </div>
                                                    {selectedOption === IndustryValue.Other && (
                                                        <div className={`${COMPONENT_CLASS}__field`}>
                                                            {errors.otherIndustry && (
                                                                <div role="alert" className={`${COMPONENT_CLASS} -error`}>
                                                                    {t('pricing.organizationPage.enterIndustry')}
                                                                </div>
                                                            )}
                                                            <TextTypeInput
                                                                id='otherIndustry'
                                                                label={t('pricing.organizationPage.industry')}
                                                                hideLabel={true}
                                                                inputClassName={`c-input__input ${errors.otherIndustry && `${COMPONENT_CLASS} -showerror`}`}
                                                                registerHook={register}
                                                                registerOptions={{
                                                                    required: true,
                                                                    value: otherIndustry
                                                                }}
                                                                errorState={errors.otherIndustry} />
                                                        </div>
                                                    )}
                                                </>
                                            }
                                        </div>
                                    </form>
                                    <div className='mt-16 flex justify-center items-center gap-5 flex-col'>
                                        <div className='text-sm font-light'>{t('pages.linkAccountCreation.accountExists')}</div>
                                        <div>
                                            <Button
                                                type="default"
                                                buttonText={t('buttons.btn_login')}
                                                onClick={() => history.push("/login")} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ marginTop: "5%", width: "100%" }}>
                                <BaseCardLayoutActions backButton={false} submitButton={true} onSubmit={handleSubmit(onSubmit)} submitButtonText={t('buttons.btn_next')}></BaseCardLayoutActions>
                            </div>
                        </BaseCardLayout>
                )
            }
        </>
    );
}
export default LinkAccountCreation;