/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { MskScore } from "../../models/interfaces/msk-score";
import { User } from "../../models/interfaces/user";
import MskScoreService from "../../utilities/services/msk-score-service";
import moment from "moment";
import { Button } from "../button/button";
import { MskScoreLevels } from "../../models/enumerations/msk-score-levels";
import AssessmentBarChart from "../assessment-bar-chart/assessment-bar-chart";
import { useTranslation } from "react-i18next";
import MskScoreUtil from "../../utilities/msk-score-util";
import ReportDataUtil from "../../utilities/report-data-util";

const COMPONENT_CLASS = "c-user-profile-history";

// -----------------------------------------------------------------------------------------
// #region Intefaces
// -----------------------------------------------------------------------------------------

interface UserProfileHistoryProps {
    allMskScores: MskScore[];
    setAllMskScores: Function;
    user: User;
}

// #endregion Intefaces

// -----------------------------------------------------------------------------------------
// #region Component
// -----------------------------------------------------------------------------------------

const UserProfileHistory: React.FC<UserProfileHistoryProps> = (props: UserProfileHistoryProps) => {
    const { t } = useTranslation();
    const { user, allMskScores, setAllMskScores } = props;
    const [validMskScores, setValidMskScores] = useState<MskScore[]>();

    useEffect(() => {
        const getAllMskScores = async () => {
            if (!allMskScores) {
                const mskScores = await MskScoreService.getBy([{
                    field: "userId",
                    operator: "==",
                    value: user.id
                }], [{
                    field: "created",
                    direction: "asc"
                }])

                setAllMskScores(mskScores);
            }
        }
        getAllMskScores();
    }, [])

    useEffect(() => {
        const initialLoad = () => {
            const tempValidMskScores = [];
            for (const mskScore of allMskScores) {
                if (MskScoreUtil.checkMskScore(mskScore)) {
                    tempValidMskScores.push(mskScore);
                }
            }
            setValidMskScores(tempValidMskScores);
        }

        if (allMskScores) {
            initialLoad();
        }
    }, [allMskScores])

    return (
        <div className={`${COMPONENT_CLASS}`}>
            {(validMskScores && validMskScores.length > 0) ?
                <table
                    role={"table"}
                    className={"dark list__table-container"}
                    data-testid="deeplinks-list">
                    <thead className="hide-on-mobile">
                        <tr className="uppercase">
                            <th>{t('userProfile.date')}</th>
                            <th>{t('userProfile.wellnessScore')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {validMskScores?.map((mskScore, index) => {
                            return (
                                <tr
                                    className="table-row-container"
                                    key={index}
                                >
                                    <td>
                                        <strong className="show-on-mobile mr-1 uppercase">{t('userProfile.dateColon')}</strong>
                                        <span className="text-sm font-medium">
                                            {moment(mskScore.created?.seconds ? mskScore.created?.seconds * 1000 : NaN).format("MMM DD, YYYY")}
                                        </span>
                                    </td>
                                    <td>
                                        <strong className="show-on-mobile mr-1 uppercase">{t('userProfile.currentColon')}</strong>
                                        <div className="flex items-center gap-4">
                                            <span className={`${COMPONENT_CLASS} ${mskScore.percentage <= MskScoreLevels.Low ? "text-red-dark" : ""} text-xl font-bold leading-8 min-w-14 max-w-fit text-right`}>{mskScore.percentage}</span>
                                            <div style={{ width: "250px" }}>
                                                <AssessmentBarChart percentage={mskScore.percentage || 0} />
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="flex justify-end">
                                            <Button
                                                type="default"
                                                buttonText={t('buttons.btn_downloadReport')}
                                                onClick={() => ReportDataUtil.downloadWellness(user, mskScore)}
                                            />
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                :
                <div>
                    <p className="data-small">No Assessment History</p>
                    <p className="small-text">This user has not completed their <span className="small-text-bold">baseline Assessment</span></p>
                </div>
            }
        </div>
    );
}

export default UserProfileHistory;
